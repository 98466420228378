import { ReactNode } from "react";
import Watermark from "../Misc/Watermark";

interface Props {
	children: ReactNode;
}

export default function SimpleLayout({ children }: Props) {
	return (
		<div className="flex min-h-screen items-center justify-center bg-body relative">
			<main className="relative z-10">{children}</main>
			<Watermark />
		</div>
	);
}
