import { AxiosResponse } from "axios";
import { Customer, Engineer, User } from "../../types/Auth";
import { api } from "./api";

export interface LoginProps {
	email: string;
	password: string;
}

export const login = async (email: string, password: string, authentication_type: string) => {
	return api.post<
		LoginProps,
		AxiosResponse<{
			message: string;
			user?: Engineer;
			contact?: Customer;
		}>
	>("/authentication/login", {
		email,
		password,
		authentication_type,
	});
};

export const loginEngineer = async (email: string, password: string) => {
	return api.post<LoginProps>("/authentication/login/engineer", {
		email,
		password,
	});
};

export const register = async (email: string, password: string, first_name: string, last_name: string) => {
	return api.post("/authentication/register", {
		email,
		password,
		first_name,
		last_name,
	});
};

export const forgotten = async (email: string) => {
	return api.post("/authentication/forgot-password", {
		email,
	});
};

export const reset = async (password: string, token: string) => {
	return api.post("/authentication/reset-password", {
		password,
		token,
	});
};

export const resetWelcome = async (password: string, token: string) => {
	return api.post("/authentication/welcome", {
		password,
		token,
	});
};

export const validateResetPasswordToken = async (token: string) => {
	return api.get("/authentication/validate/reset-password/" + token);
};

export const validateWelcomePasswordToken = async (token: string) => {
	return api.get("/authentication/validate/welcome/" + token);
};

export const logout = async () => {
	return api.post<never>("/authentication/logout");
};

export const fetchUserData = async () => {
	return api.get<never, AxiosResponse<User>>("/authentication");
};
