import { useNavigate } from "react-router-dom";
import Button from "../components/Common/Button";
import { LoginForm } from "../components/Form/LoginForm";
import SimpleLayout from "../components/Layout/SimpleLayout";

function LoginPage({ mode = "customer" }: { mode?: "engineer" | "customer" }) {
	const navigate = useNavigate();
	const buttonText = mode == "customer" ? "Engineer Login" : "Customer Login";

	const handleOnClick = () => {
		navigate("/login" + (mode == "customer" ? "/engineer" : ""));
	};

	return (
		<>
			<div className="absolute right-[20px] top-[20px] z-10">
				<Button text={buttonText} onClick={handleOnClick} theme="red" />
			</div>
			<SimpleLayout>
				<div className="flex flex-col items-center">
					<div className="w-[360px] mb-[30px]">
						<img
							className="w-full block"
							src={process.env.PUBLIC_URL + "/svgs/Logo.svg"}
							alt="Process Instruments"
						/>
					</div>
					<LoginForm mode={mode} />
				</div>
			</SimpleLayout>
		</>
	);
}

export default LoginPage;
