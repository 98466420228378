import { CSSProperties } from "react";
import FadeLoader from "react-spinners/FadeLoader";

interface Props {
	color?: string;
	width?: number;
	height?: number;
	override?: CSSProperties;
}

function LoadingFullscreen({ color = "#242F60", width = 5, height = 20, override }: Props) {
	return (
		<div className="flex items-center justify-center fixed inset-0 flex-col">
			<FadeLoader
				loading={true}
				color={color}
				width={width}
				height={height}
				cssOverride={override}
				aria-label="Loading Spinner"
			/>
			<div className="mt-4">Loading...</div>
		</div>
	);
}

export default LoadingFullscreen;
