import { useRef } from "react";
import { useInfiniteQuery } from "../../hooks/useInfiniteQuery";
import { fetchPaginatedPreviousInvoices } from "../../services/api/invoices";
import SkeletonList from "../Common/SkeletonList";
import AccountInvoice from "./AccountInvoice";

interface Props {}

function AccountInvoicesPreviousTable({}: Props) {
	const observerElem = useRef(null);
	const { data, isFetchingNextPage, hasNextPage, status, error } = useInfiniteQuery({
		queryKey: "Account.Invoices.Previous",
		queryFn: fetchPaginatedPreviousInvoices,
		loadMoreRef: observerElem,
	});

	return status === "loading" ? (
		<SkeletonList rows={3} />
	) : status === "error" ? (
		<p>Error: {error.message}</p>
	) : (
		<>
			<div className="font-display text-base tracking-wider uppercase text-secondary">Previous Invoices</div>
			<div>
				<div className="flex justify-between py-3 border-b border-dashed font-semibold text-primary text-xs sm:text-sm">
					<div className="w-1/2 sm:w-1/6 uppercase pr-1">Invoice #</div>
					<div className="w-1/6 uppercase pr-1 hidden sm:block">Invoice Date</div>
					<div className="w-1/2 sm:w-1/6 uppercase pr-1">Total</div>
					<div className="w-1/6 uppercase pr-1 hidden sm:block"></div>
					<div className="hidden sm:block flex-1"></div>
				</div>

				{data!.pages.map((page, i) =>
					page.results?.map((invoice: any, index: number) => <AccountInvoice key={index} invoice={invoice} />)
				)}

				{isFetchingNextPage && <SkeletonList rows={3} />}

				<div ref={observerElem}></div>
			</div>
		</>
	);
}

export default AccountInvoicesPreviousTable;
