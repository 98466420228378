import { ReactNode, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Button from "../Common/Button";
import ModalOverlay from "../Common/ModalOverlay";
import PageTitle from "../Common/PageTitle";
import NavTabs from "../Navigation/NavTabs";
import SearchBar from "../Search/SearchBar";
import StandardLayout from "./StandardLayout";

interface Props {
	children: ReactNode;
	mode?: string | undefined;
	search?: boolean;
	searchTerm?: undefined | string;
	setSearchTerm?: any;
}

function TicketsLayout({ mode = "open", search = false, searchTerm = undefined, setSearchTerm, children }: Props) {
	const navigate = useNavigate();
	const tabData = [
		{ name: "Open Tickets", href: "/tickets/open", sel: mode == "open" ? true : false },
		{ name: "Closed Tickets", href: "/tickets/closed", sel: mode == "closed" ? true : false },
	];

	const [toggleModal, setToggleModal] = useState(false);
	return (
		<>
			<ModalOverlay title="Add New Ticket" isShowing={toggleModal} onClick={setToggleModal}>
				<div>Awaiting response from API</div>
			</ModalOverlay>

			<StandardLayout>
				<div className="flex items-center mb-4 justify-between">
					<div className="flex items-center">
						<PageTitle title="Tickets" style={{ marginBottom: 0, marginRight: 10 }} />
						<Button text="New Ticket" theme="red" onClick={() => navigate("/tickets/new")} />
					</div>
					{search ? (
						<SearchBar placeholder="Search Tickets" value={searchTerm} onChange={setSearchTerm} />
					) : (
						""
					)}
				</div>

				<NavTabs navigation={tabData} />
				{children}
			</StandardLayout>
		</>
	);
}

export default TicketsLayout;
