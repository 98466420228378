import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { setUser } from "../../redux/reducers/userSlice";
import { store } from "../../redux/store";
import { updateAccount } from "../../services/api/account";
import { api } from "../../services/api/api";
import Button from "../Common/Button";
import Loading from "../Common/Loading";

interface Props {
	onClick: any;
}

const EditAccountDetails = ({ onClick }: Props) => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	//Current user information in the store
	const userState = store.getState().user.user;

	const [toggleLoading, setToggleLoading] = useState(false);

	const onSubmit = async (data: any) => {
		setToggleLoading(true);
		try {
			const res = await updateAccount(data.first_name, data.last_name, data.phone_number, data.mobile_number);
			if (res.status == 200) {
				//Show Success
				setToggleLoading(false);
				onClick(false);
				toast.success("Account Details Successfully Updated", { theme: "colored", position: "top-center" });
				//Regrab user info making sure backend matches front end
				const user = await api.get("/authentication");
				store.dispatch(setUser(user.data));
			} else if (res.status == 403) {
				//Show Error
				setToggleLoading(false);
				toast.error("Something Went Wrong Try Again.", { theme: "colored", position: "top-center" });
			}
		} catch (err: any) {
			//Show Error
			setToggleLoading(false);
			toast.error("Something Went Wrong Try Again.", { theme: "colored", position: "top-center" });
		}
	};

	const closeHandler = (e: any) => {
		e.preventDefault();
		onClick(false);
	};

	return (
		<>
			{toggleLoading ? (
				<div className="p-2">
					<Loading />
				</div>
			) : (
				<div className="mt-2">
					<form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
						<div>
							<label htmlFor="first_name">First Name</label>
							<input
								className="appearance-none block w-full rounded-md mt-2 text-sm p-2 border border-primary"
								type="text"
								id="first_name"
								defaultValue={userState?.first_name}
								{...register("first_name")}
							/>
						</div>
						<div>
							<label htmlFor="last_name">Last Name</label>
							<input
								className="appearance-none block w-full rounded-md text-sm p-2 border border-primary"
								type="text"
								id="last_name"
								defaultValue={userState?.last_name}
								{...register("last_name")}
							/>
						</div>
						<div>
							<label htmlFor="phone_number">Phone Number</label>
							<input
								className="appearance-none block w-full rounded-md text-sm p-2 border border-primary"
								type="text"
								id="phone_number"
								defaultValue={userState?.phone_number}
								{...register("phone_number")}
							/>
						</div>
						<div>
							<label htmlFor="mobile_number">Mobile Number</label>
							<input
								className="appearance-none block w-full rounded-md text-sm p-2 border border-primary"
								type="text"
								id="mobile_number"
								defaultValue={userState?.mobile_number}
								{...register("mobile_number")}
							/>
						</div>

						<div className="flex items-center justify-between">
							<Button text="Dismiss" theme="red" onClick={closeHandler} />
							<Button text="Update Contact Details" type="submit" />
						</div>
					</form>
				</div>
			)}
		</>
	);
};

export default EditAccountDetails;
