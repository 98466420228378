import { useContext, useEffect, useState } from "react";
import {
	InvoiceMultiActionerActionEnum,
	InvoiceMultiActionerCtx,
	InvoiceMultiActionerProvider,
} from "../../contexts/InvoiceMultiActioner";
import { useAccountInvoicesOutstanding } from "../../hooks/useAccountInvoicesOutstanding";
import { Invoice } from "../../types/Invoice";
import Button from "../Common/Button";
import Checkbox from "../Common/Checkbox";
import ModalOverlay from "../Common/ModalOverlay";
import SkeletonList from "../Common/SkeletonList";
import AccountInvoiceActionable from "./AccountInvoiceActionable";

interface Props {}

function AccountInvoicesOutstandingTable() {
	const [togglePasswordModal, setTogglePasswordModal] = useState(false);
	const { data, status } = useAccountInvoicesOutstanding();
	const { state, dispatch } = useContext(InvoiceMultiActionerCtx);
	const invoices = state.invoices;
	const selectedInvoicesCount = state.selectedIds.length;

	useEffect(() => {
		dispatch!({
			type: InvoiceMultiActionerActionEnum.SET_INVOICES,
			payload: data,
		});
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const toggle = () => {
		dispatch!({
			type: InvoiceMultiActionerActionEnum.TOGGLE_SELECT_ALL,
			payload: null,
		});
	};
	return status === "loading" ? (
		<SkeletonList rows={3} />
	) : status === "error" ? (
		<p>Error</p>
	) : (
		<>
			<div className="font-display text-base tracking-wider uppercase text-secondary">Outstanding Invoices</div>
			<div>
				<div className="flex justify-between py-3 border-b border-dashed font-semibold text-primary text-xs sm:text-sm">
					<div className="w-1/2 sm:w-1/6 uppercase pr-1">
						{/* <Checkbox checked={state.selectAll} onChange={toggle} label="Invoice #" /> */}
						Invoice #
					</div>
					<div className="w-1/6 uppercase pr-1 hidden sm:block">Invoice Date</div>
					<div className="w-1/2 sm:w-1/6 uppercase pr-1">Total</div>
					<div className="w-1/2 sm:w-1/6 uppercase pr-1">Outstanding</div>
					<div className="flex-1 hidden sm:block"></div>
				</div>

				{invoices &&
					invoices.map((invoice: Invoice, index: number) => (
						<AccountInvoiceActionable key={index} invoice={invoice} />
					))}

				{selectedInvoicesCount > 0 && (
					<Button
						onClick={() => setTogglePasswordModal(true)}
						style={{ marginTop: 20 }}
						theme="red"
						text={`Download ${selectedInvoicesCount} Invoice(s)`}
					/>
				)}

				<ModalOverlay title="Payment Methods" isShowing={togglePasswordModal} onClick={setTogglePasswordModal}>
					<div className="mt-6 mb-4">Awaiting response from API</div>
					<div className="flex justify-between">
						<Button text="Dismiss" theme="red" onClick={() => setTogglePasswordModal(false)} />
						<div className="opacity-20 pointer-events-none">
							<Button text="Pay" type="submit" disabled />
						</div>
					</div>
				</ModalOverlay>
			</div>
		</>
	);
}

const Wrapper = (props: Props) => {
	return (
		<InvoiceMultiActionerProvider>
			<AccountInvoicesOutstandingTable {...props} />
		</InvoiceMultiActionerProvider>
	);
};

export default Wrapper;
