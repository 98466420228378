import { useRef } from "react";
import { useInfiniteQuery } from "../../hooks/useInfiniteQuery";
import { fetchPaginatedDocuments, searchDocuments } from "../../services/api/documents";
import { Document } from "../../types/Document";
import NoDataReturned from "../Common/NoDataReturned";
import SkeletonList from "../Common/SkeletonList";
import DocumentRow from "./DocumentRow";

const DocumentsList = ({ searchTerm }: { searchTerm?: undefined | string }) => {
	const observerElem = useRef(null);
	const isSearching = searchTerm !== undefined && searchTerm.trim() !== "";

	const allDocumentsQuery = useInfiniteQuery({
		queryKey: ["Documents"],
		queryFn: fetchPaginatedDocuments,
		loadMoreRef: observerElem,
	});

	const searchQuery = useInfiniteQuery({
		queryKey: ["Documents", "Search", searchTerm ?? ""],
		queryFn: searchDocuments,
		loadMoreRef: observerElem,
		enabled: isSearching === true,
	});

	const noDataText = isSearching
		? "You have no documents matching ’" + searchTerm + "’ available"
		: "You have no documents available";

	const query = isSearching ? searchQuery : allDocumentsQuery;

	return query.status === "loading" ? (
		<SkeletonList rows={10} />
	) : query.status === "error" ? (
		<p>Error: {query.error.message}</p>
	) : (
		<>
			{query.data?.pages[0].results && query.data?.pages[0].results.length ? (
				<>
					{isSearching ? <div className="mb-4 text-sm">Showing results for: {searchTerm}</div> : null}
					<div className="flex justify-between p-3 sm:px-5 text-xs sm:text-sm font-display text-secondary uppercase">
						<div className="w-1/5">Order #</div>
						<div className="flex-1">Doucment Title</div>
					</div>
					{query.data?.pages.map((page, i) =>
						page.results?.map((document: Document, index: number) => (
							<DocumentRow key={index} document={document} />
						))
					)}
					<div ref={observerElem}>
						{query.isFetchingNextPage ? (
							<SkeletonList rows={3} />
						) : query.hasNextPage ? (
							<SkeletonList rows={3} />
						) : (
							""
						)}
					</div>
				</>
			) : (
				<NoDataReturned text={noDataText} />
			)}
		</>
	);
};

export default DocumentsList;
