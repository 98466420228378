import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { store } from "../../redux/store";
import { updateAccountPassword } from "../../services/api/account";
import Button from "../Common/Button";
import Loading from "../Common/Loading";

interface Props {
	onClick: any;
}

const EditAccountPassword = ({ onClick }: Props) => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	//Current user information in the store
	const userState = store.getState().user.user;

	const [toggleLoading, setToggleLoading] = useState(false);

	const onSubmit = async (data: any) => {
		setToggleLoading(true);
		try {
			const res = await updateAccountPassword(data.current_password, data.new_password);
			if (res.status == 200) {
				//Show Success
				setToggleLoading(false);
				onClick(false);
				toast.success("Account Password Successfully Updated", { theme: "colored", position: "top-center" });
			} else if (res.status == 403) {
				setToggleLoading(false);
				toast.error("Current Password Incorrect", { theme: "colored", position: "top-center" });
			}
		} catch (err: any) {
			setToggleLoading(false);
			toast.error("Current Password Incorrect", { theme: "colored", position: "top-center" });
		}
	};

	const closeHandler = (e: any) => {
		e.preventDefault();
		onClick(false);
	};

	return (
		<>
			{toggleLoading ? (
				<div className="p-2">
					<Loading />
				</div>
			) : (
				<div className="mt-2">
					<form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
						<div>
							<label htmlFor="current_password">Current Password</label>
							<input
								className="appearance-none block w-full rounded-md mt-2 text-sm p-2 border border-primary"
								type="password"
								id="current_password"
								{...register("current_password")}
							/>
						</div>
						<div>
							<label htmlFor="new_password">New Password</label>
							<input
								className="appearance-none block w-full rounded-md text-sm p-2 border border-primary"
								type="password"
								id="new_password"
								{...register("new_password")}
							/>
						</div>

						<div className="flex items-center justify-between">
							<div className="order-1">
								<Button text="Update Password" type="submit" />
							</div>
							<Button text="Dismiss" theme="red" onClick={closeHandler} />
						</div>
					</form>
				</div>
			)}
		</>
	);
};

export default EditAccountPassword;
