import { Order } from "../../types/Order";

interface Props {
	order: Order;
}

function OrderTableDetails({ order }: Props) {
	// TODO: Update once API returns this data
	const shipping = "Royal Mail - Tracked";
	const tracking = "#12345678910";
	return (
		<div className="flex flex-wrap text-sm">
			{order.addresses.delivery && (
				<div className="w-1/2 sm:w-1/3 pr-9">
					<div className="uppercase text-black mb-3">Delivery Address</div>
					<div className="text-display text-secondary leading-4">
						{order.addresses.delivery.map((row, index) => (
							<div key={index}>{row}</div>
						))}
					</div>
				</div>
			)}
			{order.addresses.billing && (
				<div className="w-1/2 sm:w-1/3 pr-9">
					<div className="uppercase text-black mb-3">Billing Address</div>
					<div className="text-display text-secondary leading-4">
						{order.addresses.billing.map((row, index) => (
							<div key={index}>{row}</div>
						))}
					</div>
				</div>
			)}
			{/* {(shipping || tracking) && (
				<div className="w-1/3">
					{shipping && (
						<div className="mb-4">
							<div className="uppercase text-black mb-3">Shipping Method</div>
							<div className="text-display text-secondary leading-5">{shipping}</div>
						</div>
					)}
					{tracking && (
						<div>
							<div className="uppercase text-black mb-3">Tracking Number</div>
							<div className="text-display text-secondary leading-5">{tracking}</div>
						</div>
					)}
				</div>
			)} */}
		</div>
	);
}

export default OrderTableDetails;
