import CardNav from "../components/Cards/CardNav";
import Grid from "../components/Layout/Grid";
import StandardLayout from "../components/Layout/StandardLayout";

function HomePageCustomer() {
	//To be removed potentially
	const data = [
		{
			icon: "parcel.svg",
			title: "Orders",
			path: "/orders",
			// description:
			//   "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae venenatis",
		},
		{
			icon: "question-mark.svg",
			title: "Tickets",
			path: "/tickets",
			description: "",
		},
		{
			icon: "calendar.svg",
			title: "Schedule",
			path: "/schedule",
			description: "",
		},
		{
			icon: "document.svg",
			title: "Documents",
			path: "/documents",
			description: "",
		},
		{
			icon: "user.svg",
			title: "Account",
			path: "/account",
			description: "",
		},
	];

	return (
		<StandardLayout>
			<Grid>
				{data.map((item, index) => (
					<CardNav
						key={index}
						icon={item.icon}
						title={item.title}
						path={item.path}
						description={item.description}
					/>
				))}
			</Grid>
		</StandardLayout>
	);
}

export default HomePageCustomer;
