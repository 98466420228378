import { ReactNode } from "react";
import PageTitle from "../Common/PageTitle";
import NavTabs from "../Navigation/NavTabs";
import StandardLayout from "./StandardLayout";

interface Props {
	children: ReactNode;
	title: string;
}

function AccountLayout({ title, children }: Props) {
	const tabData = [
		{ name: "Invoices", href: "/account/invoices", sel: title == "Account Invoices" ? true : false },
		{ name: "Account Details", href: "/account/details", sel: title == "Account Details" ? true : false },
	];

	return (
		<StandardLayout>
			<PageTitle title={title} />
			<NavTabs navigation={tabData} />
			{children}
		</StandardLayout>
	);
}

export default AccountLayout;
