import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import GoBack from "../components/Common/GoBack";
import SkeletonList from "../components/Common/SkeletonList";
import TicketsLayout from "../components/Layout/TicketsLayout";
import useTicket from "../hooks/useTicket";

const TicketPage = () => {
	const { id } = useParams() as { id: string };
	const query = useTicket(id);
	const ticket = query.data;

	return (
		<TicketsLayout mode={ticket?.is_closed ? "closed" : "open"}>
			{query.isLoading ? (
				<SkeletonList rows={3} />
			) : query.status === "error" ? (
				<p>Something went wrong</p>
			) : ticket ? (
				<div className="bg-white p-5">
					<GoBack text="Back to Tickets" />

					<div className="block xl:flex mt-3 justify-between items-center border-dashed pb-2 border-b border-offwhite text-primary">
						<div className="flex-1 font-body uppercase text-2xl text-primary">{ticket.label}</div>
						<div className="flex flex-wrap xl:justify-end items-center">
							<div className="mr-4">
								<span className="font-bold mr-1">Opened:</span>
								{dayjs.unix(ticket.created).format("DD/MM/YYYY - HH:mm")}
							</div>
							<div className="mr-4">
								<span className="font-bold mr-1">Status:</span>
								{ticket.status.label}
							</div>
							{ticket.type.label && (
								<div className="mr-4">
									<span className="font-bold mr-1">Type:</span>
									{ticket.type.label}
								</div>
							)}
							<div>
								<span className="font-bold mr-1">Priority:</span>
								{ticket.priority.label}
							</div>
						</div>
					</div>
					<div className="mt-2 text-primary">{ticket.description}</div>
				</div>
			) : (
				<div>No Ticket Found</div>
			)}
		</TicketsLayout>
	);
};

export default TicketPage;
