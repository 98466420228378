import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Common/Button";
import { useNavigate, useParams } from "react-router-dom";
import useWelcomePassword from "../../hooks/useWelcomePassword";

type FormFields = {
	password: string;
	password_confirm: string;
};

export const WelcomePasswordForm = () => {
	const { token } = useParams() as { token: string };
	const { register, handleSubmit } = useForm<FormFields>();
	const { mutate } = useWelcomePassword();
	const [error, setError] = useState<null | string>(null);
	const [successMessage, setSuccessMessage] = useState<null | string>(null);
	const [isProcessing, setIsProcessing] = useState(false);
	const navigate = useNavigate();

	const onSubmit = async (data: FormFields) => {
		setError(null);
		setSuccessMessage(null);
		setIsProcessing(true);

		if (Object.values(data).some((val: string) => val.trim() === "")) {
			setError("Please fill in all fields");
			setIsProcessing(false);
			return;
		}

		if (data.password !== data.password_confirm) {
			setError("Password and Password Confirmation must match");
			setIsProcessing(false);
			return;
		}

		const payload = {
			password: data.password,
			token,
		};

		mutate(payload, {
			onSuccess: (response) => {
				setSuccessMessage(response.data.message);
			},
			onError: (error: any) => {
				setError(error.response.data.message);
			},
			onSettled: () => {
				setIsProcessing(false);
			},
		});
	};

	return (
		<>
			<div className="flex flex-col justify-center flex-1">
				<div className="w-[400px] bg-primary p-5 rounded">
					<div className="font-body text-white text-center text-2xl tracking-wide pb-3">Set Password</div>

					<div className="mt-1 w-full">
						{error && (
							<div className="bg-red py-2 px-4 text-center text-white mb-4 rounded text-sm">{error}</div>
						)}
						{successMessage ? (
							<div>
								<div className="bg-lime-500 py-2 px-4 text-center text-white mb-4 rounded text-sm">
									{successMessage}
								</div>
								<Button
									className="w-full"
									text="Login"
									theme="red"
									onClick={() => navigate("/login")}
								/>
							</div>
						) : (
							<form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
								<p className="font-medium text-sm text-white">
									Welcome to Process Instrument Solutions, please set your password below
								</p>
								<input
									className="appearance-none block w-full rounded-md text-sm p-2"
									type="password"
									{...register("password")}
									placeholder="New Password"
								/>
								<input
									className="appearance-none block w-full rounded-md text-sm p-2"
									type="password"
									{...register("password_confirm")}
									placeholder="New Password Confirmation"
								/>

								<div>
									<div>
										<Button
											className="w-full"
											isProcessing={isProcessing}
											text="Submit"
											theme="red"
											type="submit"
										/>
									</div>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
