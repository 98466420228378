import { AxiosError, AxiosResponse } from "axios";
import { Order } from "../../types/Order";
import { api } from "./api";

export interface FetchAllOrdersResponse {
	status: string;
	message: string;
	data: {
		next_page: string | null;
		results: Order[];
	};
}

export const fetchAllOrders = async (queryKey: string | string[], pageParam: number) => {
	try {
		const { data } = await api.get<never, AxiosResponse<FetchAllOrdersResponse>, AxiosError<any>>(
			`/orders?page=${pageParam}`
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

//Fetches all lines on one specific order
export const fetchOrderLines = async (id: string) => {
	const { data } = await api.get(`/orders/${id}/lines`);

	return data;
};

export interface FetchOrderByIdResponse {
	status: string;
	message: string;
	data: Order;
}

export const fetchOrderById = async (id: number) => {
	try {
		const res = await api.get<never, AxiosResponse<FetchOrderByIdResponse>>(`/user/orders/${id}`);

		if (res.status === 200) {
			if (res.data.status === "200") {
				return res.data;
			}
		}
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const searchOrders = async (queryKey: string[], pageParam: number) => {
	const searchTerm = queryKey.pop();
	try {
		const { data } = await api.post<{ query: string }, AxiosResponse<any>>(`/orders/search?page=${pageParam}`, {
			query: searchTerm,
		});

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};
