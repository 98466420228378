import { useNavigate } from "react-router-dom";
import Button from "../components/Common/Button";
import { LoginForm } from "../components/Form/LoginForm";
import SimpleLayout from "../components/Layout/SimpleLayout";
import { RegisterForm } from "../components/Form/RegisterForm";

function RegisterPage() {
	const navigate = useNavigate();

	const handleOnClick = () => {
		navigate("/login");
	};

	return (
		<>
			<div className="absolute right-[20px] top-[20px] z-10">
				<Button text={"Customer Login"} onClick={handleOnClick} theme="red" />
			</div>
			<SimpleLayout>
				<div className="flex flex-col items-center">
					<div className="w-[360px] mb-[30px]">
						<img
							className="w-full block"
							src={process.env.PUBLIC_URL + "/svgs/Logo.svg"}
							alt="Process Instruments"
						/>
					</div>
					<RegisterForm />
				</div>
			</SimpleLayout>
		</>
	);
}

export default RegisterPage;
