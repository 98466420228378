import { useQuery } from "react-query";
import { setUser } from "../redux/reducers/userSlice";
import { store } from "../redux/store";
import { fetchUserData } from "../services/api/auth";

export const useAuth = () => {
	return useQuery({
		queryKey: "Auth.User",
		queryFn: fetchUserData,
		select: (response) => response.data,
		retry: false,
		onSuccess: (user) => {
			store.dispatch(setUser(user));
		},
	});
};
