import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { store } from "../../redux/store";
import NavSidebar from "../Navigation/NavSidebar";
import { GiHamburgerMenu } from "react-icons/gi";
import { selectIsEngineer } from "../../redux/reducers/userSlice";
import { useSelector } from "react-redux";

function Sidebar() {
	const userState = store.getState().user.user;

	const [navHeight, setNavHeight] = useState<number | string>(window.innerWidth < 900 ? 0 : "auto");

	const isEngineer = useSelector(selectIsEngineer);

	useEffect(() => {
		if (window.innerWidth > 900) {
			setNavHeight("auto");
		}
	}, []);

	return (
		<div className="shrink-0 w-full main:w-[200px] lg:w-[300px] shrink-0 bg-primaryDarker flex flex-col">
			<div className="flex-1">
				<div className="flex items-center p-4 main:block main:p-6 font-display text-white border-b-2 border-primary">
					<div className="text-lg leading-none mr-2">Welcome back</div>
					<div className="flex-1 main:mb-1 text-lg font-bold">{userState?.first_name}</div>
					{!isEngineer && (
						<Link to="/account/details" className="text-sm hidden sm:block">
							Manage Account
						</Link>
					)}
					<div
						onClick={() => setNavHeight(navHeight === 0 ? "auto" : 0)}
						className="main:hidden cursor-pointer pl-4"
					>
						<GiHamburgerMenu size={28} />
					</div>
				</div>
				<NavSidebar revealHeight={navHeight} />
			</div>
			<div className="hidden lg:flex fixed bottom-[20px]  flex-col text-white pb-5 px-6">
				<a href="https://www.processinstrumentsolutions.co.uk/" target="_blank">
					processinstrumentsoultions.co.uk
				</a>
				<a href="tel:+441449674986">+44 (0)1449 674 986</a>
			</div>
		</div>
	);
}

export default Sidebar;
