import dayjs from "dayjs";
import { useState } from "react";
import { Appointments } from "../../types/Appointment";
import AppointmentReveal from "./AppointmentReveal";
// import OrderTableReveal from "./OrderTableReveal";

interface Props {
	data: Appointments;
}

//Hardcoded for now
function AppointmentRow({ data }: Props) {
	const [height, setHeight] = useState<number | string>(0);

	//Used for turning the plus into a minus
	let plusStyle = {
		transform: height === 0 ? "rotate(0deg)" : "rotate(90deg)",
		transition: "transform 150ms ease",
	};

	return (
		<div className="border-dashed border-b border-offwhite rounded-lg mb-4">
			<div className="flex items-center cursor-pointer" onClick={() => setHeight(height === 0 ? "auto" : 0)}>
				<div className="flex-1 flex font-display text-sm">
					<div className="w-1/2 md:w-[180px] xl:w-[220px] ">
						{dayjs.unix(data.schedule_date).format("DD/MM/YYYY -  HH:mm:ss")}
					</div>
					<div className="w-1/2 md:w-[180px] xl:w-[220px] ">{data.name}</div>
					<div className="hidden md:block flex-1">{data.address}</div>
				</div>
				<div className="p-5">
					<div className="shrink-0 w-[10px] h-[10px] relative">
						<div className="w-[10px] h-0.5 bg-primary absolute top-2/4 left-2/4 ml-[-5px] -mt-px"></div>
						<div
							className="w-0.5 h-[10px] bg-primary absolute top-2/4 left-2/4 mt-[-5px] -ml-px"
							style={plusStyle}
						></div>
					</div>
				</div>
			</div>
			<AppointmentReveal height={height} data={data} />
		</div>
	);
}

export default AppointmentRow;
