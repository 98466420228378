interface Props {
	label?: string;
	value?: string;
}

const AccountDetailsItem = ({ label, value }: Props) => {
	return (
		<div className="basis-6/12 pb-6">
			<div className="text-sm tracking-wider uppercase text-black pb-2">{label}</div>
			<div className="font-medium text-sm text-secondary whitespace-pre-wrap">{value}</div>
		</div>
	);
};

export default AccountDetailsItem;
