import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

type Props = {
	placeholder: string;
	value: string | undefined;
	onChange: (value: string | undefined) => void;
};

export default function SearchBar({ placeholder, value, onChange }: Props) {
	const [searchTerm, setSearchTerm] = useState<string>(value ?? "");

	const delayedQuery = useCallback(
		_.debounce((searchTerm) => onChange(searchTerm), 600),
		[]
	);

	useEffect(() => {
		if (searchTerm && searchTerm.trim() !== "") {
			delayedQuery(searchTerm);
		} else {
			delayedQuery.cancel();
			onChange(searchTerm);
		}
		return () => {};
	}, [searchTerm]);

	return (
		<div className="flex items-center">
			{value && value.trim() !== "" && (
				<div
					onClick={() => {
						onChange(undefined);
						setSearchTerm("");
					}}
					className="mr-3"
				>
					<img
						className="w-4 block cursor-pointer"
						src={process.env.PUBLIC_URL + "/svgs/cross.svg"}
						alt="Clear Search"
					/>
				</div>
			)}
			<div className="relative flex">
				<img
					className="w-4 block absolute pointer-events-none top-[7px] left-2"
					src={process.env.PUBLIC_URL + "/svgs/search.svg"}
					alt="Search"
				/>
				<input
					className="border rounded py-1 px-5 pl-8 text-sm placeholder-current"
					type="text"
					value={searchTerm}
					onChange={(value) => setSearchTerm(value.currentTarget.value)}
					placeholder={placeholder}
				/>
			</div>
		</div>
	);
}
