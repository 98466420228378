import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { isConstructorDeclaration } from "typescript";
import { store } from "../../redux/store";
import { updateAccount } from "../../services/api/account";
import Button from "../Common/Button";
import Loading from "../Common/Loading";
import ModalOverlay from "../Common/ModalOverlay";
import EditAccountAddress from "../Form/EditAccountAddress";
import EditAccountDetails from "../Form/EditAccountDetails";
import EditAccountPassword from "../Form/EditAccountPassword";

//TODO: Add acutal functionality to update contact details / address info

function AccountDetailsAction() {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	const userState = store.getState().user.user;

	const [toggleEditModal, setToggleEditModal] = useState(false);
	const [togglePasswordModal, setTogglePasswordModal] = useState(false);
	const [toggleAddressModal, setToggleAddressModal] = useState(false);

	return (
		<>
			<ModalOverlay title="Edit your contact details" isShowing={toggleEditModal} onClick={setToggleEditModal}>
				<EditAccountDetails onClick={setToggleEditModal} />
			</ModalOverlay>
			<ModalOverlay title="Change your password" isShowing={togglePasswordModal} onClick={setTogglePasswordModal}>
				<EditAccountPassword onClick={setTogglePasswordModal} />
			</ModalOverlay>

			{/*
			<ModalOverlay title="Edit your address" isShowing={toggleAddressModal}>
				<EditAccountAddress onClick={setToggleAddressModal} />
			</ModalOverlay>
*/}
			<div className="flex flex-wrap justify-between">
				<div className="w-full sm:basis-6/12 flex">
					<div className="mr-2">
						<Button text="Edit Contact Details" theme="red" onClick={() => setToggleEditModal(true)} />
					</div>
					<div>
						<Button text="Change Password" onClick={() => setTogglePasswordModal(true)} />
					</div>
				</div>
				<div className="basis-6/12">
					<div>{/* <Button text="Edit Address" theme="red" /> */}</div>
				</div>
			</div>
		</>
	);
}

export default AccountDetailsAction;
