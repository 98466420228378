import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUpdateAppointment } from "../services/api/appointments";

const useUpdateAppointment = () => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return useMutation(
		({
			id,
			status_id,
			notes,
			tasks,
		}: {
			id: string;
			status_id: string;
			notes: { id: string; notes: string }[];
			tasks: { id: string; notes: string }[];
		}) => setUpdateAppointment({ id, status_id, notes, tasks }),
		{
			onSuccess: (response, variables) => {
				toast.success("Updated Appointment", { theme: "colored", position: "top-center" });
				queryClient.setQueryData(["Appointments", variables.id], response);
				queryClient.invalidateQueries(["Appointments", variables.id, "Tasks"]);
				// if(status_id == "3") {
				navigate(`/schedule`);
				// }
			},
		}
	);
};

export default useUpdateAppointment;
