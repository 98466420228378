import { CSSProperties } from "react";

interface Props {
	title: string;
	style?: CSSProperties;
}

function PageTitle({ title, style }: Props) {
	return (
		<div className="font-body uppercase text-3xl text-primary mb-4" style={style}>
			{title}
		</div>
	);
}

export default PageTitle;
