import dayjs from "dayjs";
import { API_BASE_URL } from "../../services/api/api";
import { Invoice } from "../../types/Invoice";
import Button from "../Common/Button";

interface Props {
	invoice: Invoice;
}

function AccountInvoice({ invoice }: Props) {
	const handlePdf = () => {
		window.open(API_BASE_URL + `/invoices/${invoice.id}/download`, "_blank");
	};
	return (
		<div>
			<div className="flex flex-wrap justify-between items-center py-5 border-b border-dashed font-semibold text-primary text-sm">
				<div className="w-1/2 sm:w-1/6 uppercase pr-1">
					<div>{invoice.id}</div>
				</div>
				<div className="w-1/6 uppercase pr-1 hidden sm:block">
					{dayjs.unix(invoice.created).format("DD/MM/YYYY")}
				</div>
				<div className="w-1/2 sm:w-1/6 uppercase pr-1">&pound;{invoice.total_formatted}</div>
				{invoice.has_outstanding ? (
					<div className="w-1/6 uppercase pr-1">&pound;{invoice.payments_formatted}</div>
				) : (
					""
				)}
				<div className="w-full mt-3 sm:mt-0 sm:w-auto sm:flex-1 flex sm:justify-end">
					<Button onClick={handlePdf} text="DOWNLOAD PDF" />
				</div>
			</div>
		</div>
	);
}

export default AccountInvoice;
