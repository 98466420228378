import { useMutation } from "react-query";
import { register } from "../services/api/auth";

export default function useRegister() {
	return useMutation(
		({
			email,
			password,
			first_name,
			last_name,
		}: {
			email: string;
			password: string;
			first_name: string;
			last_name: string;
		}) => {
			return register(email, password, first_name, last_name);
		}
	);
}
