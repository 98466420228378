import axios from "axios";

const BASE_URL = "https://portal.processinstrumentsolutions.co.uk";
// const BASE_URL = "http://localhost:9001";

const API_BASE_URL = BASE_URL + "/api/v1";

export const api = axios.create({
	baseURL: API_BASE_URL,
	withCredentials: true,
});

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		console.log(error);
		// if (error.response.status === 401) {
		//   location.reload();
		// } else if (error.response.status === 403) {
		//   toast.error("403");
		//   return Promise.reject(error);
		// } else if (error.response.status === 404) {
		//   return Promise.reject(error);
		// } else if (error.response.status === 429) {
		//   toast.error("Too many requests, please wait a moment and try again");
		// } else {
		//   return Promise.reject(error);
		// }
	}
);

export const Api = axios;
export { API_BASE_URL, BASE_URL };
