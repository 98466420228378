import { AxiosError, AxiosResponse } from "axios";
import { Ticket, Status, Priority } from "../../types/Ticket";
import { api } from "./api";

export interface FetchAllTicketsResponse {
	status: string;
	message: string;
	data: {
		next_page: string | null;
		results: Ticket[];
	};
}

export const fetchAllOpenTickets = async (queryKey: string | string[], pageParam: number) => {
	try {
		const { data } = await api.get<never, AxiosResponse<FetchAllTicketsResponse>, AxiosError<any>>(
			`/tickets/open?page=${pageParam}`
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const fetchAllClosedTickets = async (queryKey: string | string[], pageParam: number) => {
	try {
		const { data } = await api.get<never, AxiosResponse<FetchAllTicketsResponse>, AxiosError<any>>(
			`/tickets/closed?page=${pageParam}`
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const searchTicketsOpen = async (queryKey: string[], pageParam: number) => {
	const searchTerm = queryKey.pop();
	try {
		const { data } = await api.post<{ query: string }, AxiosResponse<any>>(
			`/tickets/open/search?page=${pageParam}`,
			{
				query: searchTerm,
			}
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const searchTicketsClosed = async (queryKey: string[], pageParam: number) => {
	const searchTerm = queryKey.pop();
	try {
		const { data } = await api.post<{ query: string }, AxiosResponse<any>>(
			`/tickets/closed/search?page=${pageParam}`,
			{
				query: searchTerm,
			}
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const fetchTicket = async (id: string) => {
	try {
		const { data } = await api.get<never, AxiosResponse<Ticket>, AxiosError<any>>(`/tickets/${id}`);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const fetchTicketTypes = async () => {
	try {
		const { data } = await api.get<never, AxiosResponse<Status[]>, AxiosError<any>>(`/tickets/types`);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const fetchTicketPriorites = async () => {
	try {
		const { data } = await api.get<never, AxiosResponse<Priority[]>, AxiosError<any>>(`/tickets/priorities`);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export interface CreateTicketProps {
	label: string;
	description: string;
	crm_ticket_priority_id: number;
	crm_ticket_type_id: number;
}

export const createTicket = async ({
	label,
	description,
	crm_ticket_priority_id,
	crm_ticket_type_id,
}: CreateTicketProps) => {
	try {
		const { data } = await api.post<CreateTicketProps, AxiosResponse<any>>(`/tickets`, {
			label,
			description,
			crm_ticket_priority_id,
			crm_ticket_type_id,
		});

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};
