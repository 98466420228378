import dayjs from "dayjs";
import { Dispatches } from "../../types/Order";
import OrderProductRow from "./OrderProductRow";

interface Props {
	index: number;
	data: Dispatches;
}

function OrderProductConsignments({ index, data }: Props) {
	return (
		<div className="bg-body p-3 mt-4">
			<div className="pb-3 uppercase">
				<div className="pb-2 mb-2 border-b-2 border-gray">Consignment {index + 1}</div>
				<div className="flex text-sm">
					<div className="pr-4">Shipping Date: {dayjs.unix(data.confirmed_at).format("DD/MM/YYYY")}</div>
					{data.tracking ? <div className="pr-4">Courier: {data.shipping_method_label}</div> : ""}
					{data.tracking ? <div className="pr-4">Tracking: {data.tracking}</div> : ""}
				</div>
			</div>
			<div>
				<div className="flex uppercase text-display text-black text-sm mb-3">
					<div className="flex-1"></div>
					<div className="w-[200px] sm:w-[270px] text-right sm:text-left flex leading-5">
						<div className="w-1/2 sm:w-1/3">Price</div>
						<div className="w-1/2 sm:w-1/3">Qty</div>
						<div className="hidden sm:block w-1/3">Sub Total</div>
					</div>
				</div>
				{data.order_lines &&
					data.order_lines.map((line, index) => (
						<OrderProductRow
							key={index}
							title={line.title ? line.title : "Error loading product title"}
							price={line.price_formatted}
							qty={line.qty}
							subtotal={line.subtotal_formatted}
							border={true}
						/>
					))}
			</div>
		</div>
	);
}

export default OrderProductConsignments;
