import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";

interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	isProcessing?: boolean;
	isDisabled?: boolean;
	className?: string;
	icon?: any;
	children?: ReactNode;
	text: string;
	onClick?: (event: any) => void;
	theme?: keyof ThemeMap;
	largeStyling?: boolean;
	inline?: boolean;
}

function classNames(...classes: any) {
	return classes.filter(Boolean).join(" ");
}

interface ThemeMap {
	blue: string;
	red: string;
}

const themeMap = {
	blue: "bg-primary hover:opacity-70 text-white",
	red: "bg-secondary hover:opacity-70 text-white",
};

export default function Button({
	isProcessing,
	isDisabled = false,
	className,
	children,
	icon,
	onClick,
	text,
	largeStyling,
	inline,
	theme = "blue",
	...rest
}: ButtonProps) {
	let buttonStyling = "px-4 main:px-5 pt-[4px] pb-[3px] rounded-md ";
	if (largeStyling) {
		buttonStyling = "px-6 main:px-8 py-2 text-xl rounded-md";
	} else if (inline) {
		buttonStyling = "px-4 pt-[8px] pb-[6px] text-base items-center";
	}

	return (
		<button
			onClick={onClick}
			className={classNames(
				"group relative flex justify-center text-base font-body tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 ",
				buttonStyling,
				isProcessing ? "bg-gray-700 text-white hover:bg-gray-700 focus:ring-gray-500" : `${themeMap[theme]}`,
				isDisabled ? "cursor-default bg-gray-700 opacity-20 hover:bg-gray-700 focus:ring-gray-500" : "",
				className
			)}
			disabled={isDisabled}
			{...rest}
		>
			{(icon || isProcessing) && (
				<span className="mr-2 mt-[2px]">
					{!isProcessing && icon}
					{isProcessing && (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							className="mr-3 -ml-1 h-5 w-5 animate-spin text-white"
							viewBox="0 0 24 24"
						>
							<circle
								cx="12"
								cy="12"
								r="10"
								stroke="currentColor"
								strokeWidth="4"
								className="opacity-25"
							></circle>
							<path
								fill="currentColor"
								d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
								className="opacity-75"
							></path>
						</svg>
					)}
				</span>
			)}
			<div>{isProcessing ? "Processing" : text}</div>
		</button>
	);
}
