import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Common/Button";
import SimpleLayout from "../components/Layout/SimpleLayout";
import { ResetPasswordForm } from "../components/Form/ResetPasswordForm";
import useValidateResetPasswordToken from "../hooks/useValidateResetPasswordToken";
import { useEffect, useState } from "react";
import Loading from "../components/Common/Loading";

function ResetPasswordPage() {
	const { token } = useParams() as { token: string };
	const navigate = useNavigate();
	const { mutate } = useValidateResetPasswordToken();
	const [error, setError] = useState<null | string>(null);
	const [isProcessing, setIsProcessing] = useState(false);

	const handleOnClick = () => {
		navigate("/login");
	};

	useEffect(() => {
		mutate(
			{ token },
			{
				onSuccess: (response) => {},
				onError: (error: any) => {
					setError(error.response.data.message);
				},
				onSettled: () => {
					setIsProcessing(false);
				},
			}
		);
	}, []);

	return (
		<>
			<div className="absolute right-[20px] top-[20px] z-10">
				<Button text={"Customer Login"} onClick={handleOnClick} theme="red" />
			</div>
			<SimpleLayout>
				<div className="flex flex-col items-center">
					<div className="w-[360px] mb-[30px]">
						<img
							className="w-full block"
							src={process.env.PUBLIC_URL + "/svgs/Logo.svg"}
							alt="Process Instruments"
						/>
					</div>
					{isProcessing == false && error === null ? (
						<ResetPasswordForm
							title="Reset Your Password"
							text="To reset your password please enter your new password below"
						/>
					) : null}
					{isProcessing == true ? <Loading /> : null}
					{error !== null ? (
						<div className="flex justify-center flex-col items-center">
							<div className="bg-red py-2 px-4 text-center text-white mb-4 rounded text-sm">{error}</div>
							<Button
								className="w-full mt-4"
								text="Reset Password Again"
								theme="red"
								onClick={() => navigate("/forgot-password")}
							/>
						</div>
					) : null}
				</div>
			</SimpleLayout>
		</>
	);
}

export default ResetPasswordPage;
