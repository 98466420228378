import { useState } from "react";
import PageTitle from "../components/Common/PageTitle";
import DocumentsList from "../components/Documents/DocumentsList";
import StandardLayout from "../components/Layout/StandardLayout";
import SearchBar from "../components/Search/SearchBar";

const DocumentsPage = () => {
	const [searchTerm, setSearchTerm] = useState<undefined | string>(undefined);
	return (
		<StandardLayout>
			<div className="flex items-center justify-between">
				<PageTitle title="Documents" />
				<SearchBar placeholder="Search Documents" value={searchTerm} onChange={setSearchTerm} />
			</div>
			<DocumentsList searchTerm={searchTerm} />
		</StandardLayout>
	);
};

export default DocumentsPage;
