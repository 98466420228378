import { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { Task } from "../../types/Task";
import Button from "../Common/Button";

interface Props {
	data?: Task;
	index?: number;
	appointmentId: string;
}

const HardwareRow = ({ data, index, appointmentId }: Props) => {
	const [height, setHeight] = useState<number | string | any>(0);
	const handleReveal = () => {
		setHeight(height === 0 ? "auto" : 0);
	};

	const { register, control, setValue, watch } = useFormContext();
	const image1 = watch(`tasks[${index}].image_1`);
	const image2 = watch(`tasks[${index}].image_2`);
	const image3 = watch(`tasks[${index}].image_3`);

	const options = [
		{ value: "1", label: "Pass" },
		{ value: "2", label: "Unable to Complete" },
		{ value: "3", label: "Fail" },
	];

	useEffect(() => {
		setValue(`tasks[${index}].status_id`, data?.status.id);
		return () => {};
	}, []);

	useEffect(() => {
		register(`tasks[${index}].image_1`);
		register(`tasks[${index}].image_2`);
		register(`tasks[${index}].image_3`);
	}, []);

	// setValue("inputData", yourImage);

	const processImage = (imageId: number, event: any) => {
		const file = event.target.files[0];
		const reader = new FileReader();

		reader.readAsDataURL(file);
		reader.onloadend = function () {
			setValue(`tasks[${index}].image_${imageId}`, reader.result);
		};
	};

	return (
		<div className="pb-3 border-b mb-3 border-offwhite">
			<div className="flex flex-wrap justify-between items-center">
				<div className="w-full sm:w-auto">
					<div className="uppercase text-secondary">{data?.order_line.title}</div>
					<div className="flex text-sm text-primaryDarker">
						{data?.hardware.serial ? <div className="mr-4">Product Code: {data?.hardware.serial}</div> : ""}
						<div className="mr-4">Manufacturer: TODO</div>
					</div>
				</div>
				<div className="mt-3 sm:mt-0">
					<Button type="button" text={height === 0 ? "VIEW MORE" : "SHOW LESS"} onClick={handleReveal} />
				</div>
			</div>

			<AnimateHeight duration={500} height={height}>
				<div className="py-4">
					<div className="max-w-full text-sm">{data?.notes}</div>
					<div className="flex flex-wrap space-x-10 pt-4">
						{/* <div className="w-full sm:w-1/2 xl:w-1/3 sm:pr-2">
							<div className="text-secondary text-sm mb-2">Results:</div>
							<textarea 
							{...register(`results.${appointmentId}`)}
							defaultValue={data?.results} className="appearance-none text-sm text-primaryDarker leading-5 rounded-md border border-primaryDarker py-2 p-3 w-full min-h-[120px]">
							</textarea>
						</div> */}
						<div className="w-full sm:w-1/2 xl:w-1/3">
							<div className="text-secondary text-sm mb-2">Remedial Actions:</div>
							<textarea
								{...register(`tasks[${index}].actions_notes`)}
								defaultValue={data?.actions_notes}
								className="appearance-none text-sm text-primaryDarker leading-5 rounded-md border border-primaryDarker py-2 p-3 w-full min-h-[120px]"
							></textarea>
							<input {...register(`tasks[${index}].id`)} type="hidden" value={data?.id} />
						</div>
						<div className="w-full sm:w-1/2 xl:w-1/3">
							<div className="text-secondary text-sm mb-2">Status</div>
							<Controller
								control={control}
								name={`tasks[${index}].status_id`}
								render={({ field: { onChange, onBlur, value } }) => {
									return (
										<Select
											classNames={{
												singleValue: (state) => "text-sm p-0",
												placeholder: (state) => "text-sm p-0",
												valueContainer: () => "p-1",
												control: () => "min-h-none border-none",
												input: () => "m-0",
											}}
											placeholder="Select Status"
											options={options}
											defaultValue={options.find((c) => c.value === data?.status.id)}
											onChange={(val) => onChange(val?.value)}
										/>
									);
								}}
							/>
						</div>
					</div>
					<div>
						<div className="text-secondary text-sm mb-2">Hardware Images:</div>
						<div className="flex">
							<div>
								{image1 ? (
									<div
										className="w-[200px] h-[200px] bg-gray relative bg-cover mb-6"
										style={{ backgroundImage: `url(${image1})` }}
									></div>
								) : null}

								<input type="file" alt="Image Upload" onChange={(event) => processImage(1, event)} />
							</div>
							<div>
								{image2 ? (
									<div
										className="w-[200px] h-[200px] bg-gray relative bg-cover mb-6"
										style={{ backgroundImage: `url(${image2})` }}
									></div>
								) : null}
								<input type="file" alt="Image Upload" onChange={(event) => processImage(2, event)} />
							</div>
							<div>
								{image3 ? (
									<div
										className="w-[200px] h-[200px] bg-gray relative bg-cover mb-6"
										style={{ backgroundImage: `url(${image3})` }}
									></div>
								) : null}
								<input type="file" alt="Image Upload" onChange={(event) => processImage(3, event)} />
							</div>
						</div>
					</div>
				</div>
			</AnimateHeight>
		</div>
	);
};

export default HardwareRow;
