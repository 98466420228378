import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Common/Button";
import SimpleLayout from "../components/Layout/SimpleLayout";
import { ResetPasswordForm } from "../components/Form/ResetPasswordForm";
import { WelcomePasswordForm } from "../components/Form/WelcomePasswordForm";
import useValidateWelcomePasswordToken from "../hooks/useValidateWelcomePasswordToken";
import { useEffect, useState } from "react";
import Loading from "../components/Common/Loading";

function WelcomePage() {
	const { token } = useParams() as { token: string };
	const navigate = useNavigate();
	const [error, setError] = useState<null | string>(null);
	const [isProcessing, setIsProcessing] = useState(false);

	const handleOnClick = () => {
		navigate("/login");
	};

	return (
		<>
			<div className="absolute right-[20px] top-[20px] z-10">
				<Button text={"Customer Login"} onClick={handleOnClick} theme="red" />
			</div>
			<SimpleLayout>
				<div className="flex flex-col items-center">
					<div className="w-[360px] mb-[30px]">
						<img
							className="w-full block"
							src={process.env.PUBLIC_URL + "/svgs/Logo.svg"}
							alt="Process Instruments"
						/>
					</div>
					<WelcomePasswordForm />
				</div>
			</SimpleLayout>
		</>
	);
}

export default WelcomePage;
