import PageTitle from "../components/Common/PageTitle";
import StandardLayout from "../components/Layout/StandardLayout";
import NavTabs from "../components/Navigation/NavTabs";
import AppointmentList from "../components/Appointment/AppointmentList";

interface Props {
	mode?: "upcoming" | "past";
}

const AppointmentsPage = ({ mode }: Props) => {
	const tabData = [
		{ name: "Upcoming Visits", href: "/schedule", sel: mode == "upcoming" ? true : false },
		{ name: "Past Visits", href: "/schedule/past", sel: mode == "past" ? true : false },
	];

	return (
		<StandardLayout>
			<PageTitle title="Schedule" />
			<NavTabs navigation={tabData} />
			<div className="bg-white p-4 md:py-4 md:px-5">
				<div className="flex border-dashed border-b border-offwhite pb-4">
					<div className="flex-1 flex uppercase font-semibold text-base text-primaryDarker">
						<div className="w-1/2 md:w-[180px] xl:w-[220px] ">Date / Time</div>
						<div className="w-1/2 w-[180px] xl:w-[220px] ">Customer</div>
						<div className="hidden md:block flex-1">Location</div>
					</div>
					<div className="shrink-0 w-[50px] h-[10px]"></div>
				</div>
				<AppointmentList mode={mode} />
			</div>
		</StandardLayout>
	);
};

export default AppointmentsPage;
