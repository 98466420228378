function OrderTableHeader() {
	return (
		<div className="flex items-center">
			<div className="flex-1 grid grid-cols-4 sm:grid-cols-5 font-display text-secondary text-sm uppercase">
				<div className="py-3 px-5">Order #</div>
				<div className="py-3 px-5">Order Date</div>
				<div className="hidden sm:block py-3 px-5">Items</div>
				<div className="hidden sm:block py-3 px-5">Total</div>
				<div className="py-3 px-5">Status</div>
			</div>
			{/* I am cheating the spacing - Look at OrderTableRow to understand */}
			<div className="p-5">
				<div className="w-[10px] h-[10px]"></div>
			</div>
		</div>
	);
}

export default OrderTableHeader;
