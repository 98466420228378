import { AxiosError, AxiosResponse } from "axios";
import { Document } from "../../types/Document";
import { api } from "./api";

export interface FetchPaginatedDocumentsResponse {
	results: Document[];
	sort_method: null;
	total_results: number;
	next_page: number;
}

export const fetchPaginatedDocuments = async (queryKey: string | string[], pageParam: number) => {
	try {
		const { data } = await api.get<never, AxiosResponse<FetchPaginatedDocumentsResponse>>(
			`/documents?page=${pageParam}`
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const searchDocuments = async (queryKey: string[], pageParam: number) => {
	const searchTerm = queryKey.pop();
	try {
		const { data } = await api.post<{ query: string }, AxiosResponse<FetchPaginatedDocumentsResponse>>(
			`/documents/search?page=${pageParam}`,
			{ query: searchTerm }
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};
