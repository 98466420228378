import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface Props {
	rows?: number;
	height?: string | number;
}

const SkeletonList = ({ rows = 1, height = 50 }: Props) => {
	return <Skeleton count={rows} height={height} style={{ marginBottom: 16 }} />;
};

export default SkeletonList;
