import { useRef } from "react";
import { useInfiniteQuery } from "../../hooks/useInfiniteQuery";
import { fetchPaginatedSchedule, searchSchedule } from "../../services/api/schedule";
import { Schedule } from "../../types/Schedule";
import NoDataReturned from "../Common/NoDataReturned";
import SkeletonList from "../Common/SkeletonList";
import ScheduleRow from "./ScheduleRow";

const ScheduleList = ({ searchTerm }: { searchTerm?: undefined | string }) => {
	const observerElem = useRef(null);
	const isSearching = searchTerm !== undefined && searchTerm.trim() !== "";

	const allSchedulesQuery = useInfiniteQuery({
		queryKey: ["Schedule"],
		queryFn: fetchPaginatedSchedule,
		loadMoreRef: observerElem,
	});

	const searchQuery = useInfiniteQuery({
		queryKey: ["Schedule", "Search", searchTerm ?? ""],
		queryFn: searchSchedule,
		loadMoreRef: observerElem,
		enabled: isSearching === true,
	});

	const query = isSearching ? searchQuery : allSchedulesQuery;

	const noDataText = isSearching
		? "You have no items in your instrument schedule matching ’" + searchTerm + "’ available"
		: "You have no items in your instrument schedule";

	return query.status === "loading" ? (
		// <Loading />
		<SkeletonList rows={10} />
	) : query.status === "error" ? (
		<p>Error: {query.error.message}</p>
	) : (
		<>
			{query.data?.pages[0].results.length ? (
				<>
					{isSearching ? <div className="mb-4 text-sm">Showing results for: {searchTerm}</div> : null}
					<div className="flex justify-between py-3 px-3 sm:px-5 text-xs sm:text-sm font-display text-secondary uppercase">
						<div className="w-[130px] sm:w-[200px] xl:w-[300px]">Item</div>
						<div className="flex-1 flex text-right">
							<div className="w-1/2 sm:w-1/3">Order #</div>
							<div className="w-1/3 hidden sm:block">Date Ordered</div>
							<div className="w-1/2 sm:w-1/3">Next Service</div>
						</div>
					</div>

					{query.data?.pages.map((page, i) =>
						page.results?.map((schedule: Schedule, index: number) => <ScheduleRow data={schedule} />)
					)}

					<div ref={observerElem}>
						{query.isFetchingNextPage ? (
							<SkeletonList rows={3} />
						) : query.hasNextPage ? (
							<SkeletonList rows={3} />
						) : (
							""
						)}
					</div>
				</>
			) : (
				<NoDataReturned text={noDataText} />
			)}
		</>
	);
};

export default ScheduleList;
