import { store } from "../../redux/store";
import { Customer } from "../../types/Auth";
import AccountDetails from "./AccountDetails";
import AccountDetailsAction from "./AccountDetailsAction";
import AccountDetailsAddresses from "./AccountDetailsAddresses";

function AccountDetailsTable() {
	const userState = store.getState().user.user as Customer;

	return (
		<div className="bg-white p-5 rounded-md">
			<div className="flex flex-wrap justify-between">
				<div className="w-full sm:basis-6/12">
					<AccountDetails data={userState} />
				</div>
				{userState?.billing_address && userState?.delivery_address && (
					<div className="w-full sm:basis-6/12">
						<AccountDetailsAddresses data={userState} />
					</div>
				)}
			</div>
			{/*TODO: Harry, could you have a look over this feel like its the only way to achieve this layout and have the action buttons? Otherwise will be at different height potentially, need to think about this responsively?*/}
			<AccountDetailsAction />
		</div>
	);
}

export default AccountDetailsTable;
