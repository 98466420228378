import { Link } from "react-router-dom";

interface Props {
	icon?: string;
	title: string;
	path: string;
	description?: string;
}

function CardNav({ icon, title, path, description }: Props) {
	return (
		<Link
			to={path}
			className="border-2 border-primary bg-white rounded-3xl p-5 flex flex-col justify-center items-center"
		>
			{icon && (
				<div className="w-[68px] md:w-[78px] lg:w-[88px] xl:w-[108px]">
					<img
						className="w-full block"
						src={process.env.PUBLIC_URL + "/svgs/" + icon}
						alt="Process Instruments"
					/>
				</div>
			)}
			<div className="mt-4 font-body text-xl main:text-3xl lg:text-2xl xl:text-3xl text-primary">{title}</div>
			{description && (
				<div className="mt-4 border-t-2 border-primary py-6 font-display text-sm text-center">
					{description}
				</div>
			)}
		</Link>
	);
}

export default CardNav;
