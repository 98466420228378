import { Link } from "react-router-dom";

interface Props {
	navigation: {
		name: string;
		href: string;
		sel?: boolean;
	}[];
}

//Builds tabs that then contain content
function NavTabs({ navigation }: Props) {
	return (
		<div className="inline-flex overflow-hidden rounded-md rounded-b-none">
			{navigation.map((item, index) => (
				<Link
					key={index}
					to={item.href}
					style={item.sel === true ? { backgroundColor: "white", color: "#D9272E" } : {}}
					className="text-white text-sm block py-2 px-4 bg-primary text-center"
				>
					{item.name}
				</Link>
			))}
		</div>
	);
}

export default NavTabs;
