import AnimateHeight from "react-animate-height";
import { Link } from "react-router-dom";

interface Props {
	navigation: {
		name: string;
		href: string;
	}[];
	height?: any;
}

function NavSidebarItem({ navigation, height }: Props) {
	return (
		<AnimateHeight duration={500} height={height}>
			{navigation.map((item, index) => (
				<Link key={index} to={item.href} className="text-white text-sm block py-2 first:pt-0">
					{item.name}
				</Link>
			))}
		</AnimateHeight>
	);
}

export default NavSidebarItem;
