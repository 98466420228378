import { ReactNode } from "react";

interface Props {
	children: ReactNode;
}

function Grid({ children }: Props) {
	return <div className={"grid grid-cols-2 sm:grid-cols-3 gap-3 md:gap-5 lg:gap-8 xl:gap-10"}>{children}</div>;
}

export default Grid;
