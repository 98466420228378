import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { z } from "zod";
import Button from "../components/Common/Button";
import PageTitle from "../components/Common/PageTitle";
import StandardLayout from "../components/Layout/StandardLayout";
import useCreateTicket from "../hooks/useCreateTicket";
import useTicketPriorities from "../hooks/useTicketPriorities";
import useTicketTypes from "../hooks/useTicketTypes";

type FormData = {
	title: string;
	description: string;
	crm_ticket_type_id: string;
	crm_ticket_priority_id: string;
};

const FormSchema = z.object({
	title: z
		.string({
			required_error: "This field is required",
		})
		.min(1, { message: "This field is required" }),
	description: z
		.string({
			required_error: "This field is required",
		})
		.min(1, { message: "This field is required" }),
	crm_ticket_type_id: z.string({
		required_error: "This field is required",
	}),
	crm_ticket_priority_id: z.string({
		required_error: "This field is required",
	}),
});

const NewTicketPage = () => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<FormData>({
		resolver: zodResolver(FormSchema),
	});
	const { mutate: create } = useCreateTicket();
	const { data: ticketTypes } = useTicketTypes();
	const { data: ticketPriorities } = useTicketPriorities();
	const ticketTypeSelectionOptions = (ticketTypes ?? []).map((data) => {
		return { value: data.id, label: data.label };
	});
	const ticketPrioritiesSelectionOptions = (ticketPriorities ?? []).map((data) => {
		return { value: data.id, label: data.label };
	});

	const navigate = useNavigate();

	const onSubmit = (values: any) => {
		create(
			{
				label: values.title,
				description: values.description,
				crm_ticket_priority_id: values.crm_ticket_priority_id,
				crm_ticket_type_id: values.crm_ticket_type_id,
			},
			{
				onSuccess: (response) => {
					if (response.id > 0) {
						toast.success("Ticket successfully created", { theme: "colored", position: "top-center" });
						navigate(`/tickets/${response.id}`);
					} else {
						toast.error("Something went wrong. Please try again.", {
							theme: "colored",
							position: "top-center",
						});
					}
				},
				onError: (response) => {
					toast.error("Something went wrong. Please try again.", {
						theme: "colored",
						position: "top-center",
					});
				},
			}
		);
	};

	return (
		<StandardLayout>
			<PageTitle title="TICKETS" />
			<div className="p-5 bg-primaryDarker rounded-xl space-y-5">
				<div className="font-body uppercase text-3xl text-white mb-4">NEW TICKET</div>

				<form onSubmit={handleSubmit(onSubmit)} className="space-y-5" action="#" method="POST">
					<div className="flex-wrap flex xl:space-x-5">
						<div className="w-full mb-3 xl:mb-0 xl:flex-1">
							<input
								placeholder="Title of ticket"
								className={`appearance-none block w-full ${
									errors.title ? "rounded-t-md" : "rounded-md"
								} text-sm p-2 h-[38px]`}
								type="text"
								{...register("title")}
							/>
							{errors.title && (
								<div className="bg-red text-white text-xs py-1 px-2 rounded-b-md">
									{errors.title.message}
								</div>
							)}
						</div>
						<div className="w-1/2 pr-3 xl:pr-0 xl:w-[240px]">
							<Controller
								control={control}
								name="crm_ticket_type_id"
								render={({ field: { onChange, onBlur, value } }) => (
									<Select
										classNames={{
											singleValue: (state) => "text-sm p-0",
											placeholder: (state) => "text-sm p-0",
											valueContainer: () => "p-1",
											control: () => "min-h-none border-none",
											input: () => "m-0",
										}}
										placeholder="Choose Ticket Type"
										options={ticketTypeSelectionOptions}
										value={ticketTypeSelectionOptions.find((c) => c.value === value)}
										onChange={(val) => onChange(val?.value)}
									/>
								)}
							/>
							{errors.crm_ticket_type_id && (
								<div className="bg-red text-white text-xs py-1 px-2 rounded-b-md">
									{errors.crm_ticket_type_id.message}
								</div>
							)}
						</div>
						<div className="w-1/2 xl:w-[240px]">
							<Controller
								control={control}
								name="crm_ticket_priority_id"
								render={({ field: { onChange, onBlur, value } }) => (
									<Select
										classNames={{
											singleValue: (state) => "text-sm p-0",
											placeholder: (state) => "text-sm p-0",
											valueContainer: () => "p-1",
											control: () => "min-h-none border-none",
											input: () => "m-0",
										}}
										placeholder="Choose Urgency"
										options={ticketPrioritiesSelectionOptions}
										value={ticketPrioritiesSelectionOptions.find((c) => c.value === value)}
										onChange={(val) => onChange(val?.value)}
									/>
								)}
							/>
							{errors.crm_ticket_priority_id && (
								<div className="bg-red text-white text-xs py-1 px-2 rounded-b-md">
									{errors.crm_ticket_priority_id.message}
								</div>
							)}
						</div>
					</div>
					<div>
						<textarea
							className={`appearance-none block w-full ${
								errors.description ? "rounded-t-md" : "rounded-md"
							} text-sm p-2 min-h-[250px] `}
							placeholder="Nature and description of ticket"
							{...register("description")}
						/>
						{errors.description && (
							<div className="bg-red text-white text-xs py-1 px-2 rounded-b-md">
								{errors.description.message}
							</div>
						)}
					</div>
					<div>
						<Button theme={"red"} text="Submit Ticket" />
					</div>
				</form>
			</div>
		</StandardLayout>
	);
};

export default NewTicketPage;
