import { useQuery } from "react-query";
import { fetchAppointmentTasks } from "../../services/api/appointments";
import SkeletonList from "../Common/SkeletonList";
import HardwareRow from "./HardwareRow";

interface Props {
	id: string;
}

const HardwareList = ({ id }: Props) => {
	const tasks = useQuery({
		queryFn: () => fetchAppointmentTasks(id),
		queryKey: ["Appointments", id, "Tasks"],
	});

	if (tasks.data?.length) {
		return <div></div>;
	}

	return (
		<>
			{tasks.isLoading ? (
				<SkeletonList rows={3} />
			) : tasks.status === "error" ? (
				<p>Something went wrong</p>
			) : tasks.data?.length ? (
				<div className="uppercase text-black mb-2">Hardware</div>
			) : (
				<div>No Hardware Found</div>
			)}

			{tasks.data &&
				tasks.data.map((data, index) => (
					<HardwareRow key={data.id} data={data} index={index} appointmentId={id} />
				))}
		</>
	);
};

export default HardwareList;
