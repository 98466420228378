import AccountInvoicesOutstandingTable from "../components/Account/AccountInvoicesOutstandingTable";
import AccountInvoicesPreviousTable from "../components/Account/AccountInvoicesPreviousTable";
import AccountLayout from "../components/Layout/AccountLayout";

function AccountInvoicePage() {
	return (
		<AccountLayout title="Account Invoices">
			<div className="bg-white p-5">
				<AccountInvoicesOutstandingTable />
			</div>
			<div className="bg-white p-5">
				<AccountInvoicesPreviousTable />
			</div>
		</AccountLayout>
	);
}

export default AccountInvoicePage;
