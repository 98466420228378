import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { setUser } from "../../redux/reducers/userSlice";
import { store } from "../../redux/store";
import { login } from "../../services/api/auth";
import Button from "../Common/Button";
import useRegister from "../../hooks/useRegister";
import { useNavigate } from "react-router-dom";

type FormFields = {
	email: string;
	password: string;
	first_name: string;
	last_name: string;
};

export const RegisterForm = () => {
	const { register, handleSubmit } = useForm<FormFields>();
	const { mutate } = useRegister();
	const [toggleModal, setToggleModal] = useState(false);
	const [error, setError] = useState<null | string>(null);
	const [successMessage, setSuccessMessage] = useState<null | string>(null);
	const [isProcessing, setIsProcessing] = useState(false);
	const navigate = useNavigate();

	const onSubmit = async (data: FormFields) => {
		setError(null);
		setSuccessMessage(null);
		setIsProcessing(true);

		if (Object.values(data).some((val: string) => val.trim() === "")) {
			setError("Please fill in all fields");
			setIsProcessing(false);
			return;
		}

		mutate(data, {
			onSuccess: (response) => {
				setSuccessMessage(response.data.message);
			},
			onError: (error: any) => {
				setError(error.response.data.message);
			},
			onSettled: () => {
				setIsProcessing(false);
			},
		});
	};

	return (
		<>
			<div className="flex flex-col justify-center flex-1">
				<div className="w-[400px] bg-primary p-5 rounded">
					<div className="font-body text-white text-center text-2xl tracking-wide pb-3">Register</div>

					<div className="mt-1 w-full">
						{error && (
							<div className="bg-red py-2 px-4 text-center text-white mb-4 rounded text-sm">{error}</div>
						)}
						{successMessage ? (
							<div>
								<div className="bg-lime-500 py-2 px-4 text-center text-white mb-4 rounded text-sm">
									{successMessage}
								</div>
								<Button
									className="w-full"
									text="Login"
									theme="red"
									onClick={() => navigate("/login")}
								/>
							</div>
						) : (
							<form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
								<input
									className="appearance-none block w-full rounded-md text-sm p-2"
									type="email"
									{...register("email")}
									placeholder="Email"
								/>
								<input
									className="appearance-none block w-full rounded-md text-sm p-2"
									type="password"
									{...register("password")}
									placeholder="Password"
								/>
								<input
									className="appearance-none block w-full rounded-md text-sm p-2"
									type="text"
									{...register("first_name")}
									placeholder="First Name"
								/>
								<input
									className="appearance-none block w-full rounded-md text-sm p-2"
									type="text"
									{...register("last_name")}
									placeholder="Last Name"
								/>

								<div>
									<div className="mr-3">
										<Button
											className="w-full"
											isProcessing={isProcessing}
											text="Register"
											theme="red"
											type="submit"
										/>
									</div>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
