import { Order, OrderLine } from "../../types/Order";
import Loading from "../Common/Loading";
import OrderProductBottom from "./OrderProductBottom";
import OrderProductRow from "./OrderProductRow";
import OrderProductConsignments from "./OrderProductConsignments";
import SkeletonList from "../Common/SkeletonList";

interface Props {
	order: Order | null;
	orderLines: OrderLine[] | undefined;
}

//Wrapper for the order product details
function OrderProductTable({ orderLines, order }: Props) {
	return (
		<div className="mt-10">
			{orderLines === undefined ? (
				<SkeletonList rows={2} />
			) : (
				<div>
					<div className="flex uppercase text-display text-black text-sm mb-3">
						<div className="flex-1">Products</div>
						<div className="w-[200px] sm:w-[270px] text-right sm:text-left flex leading-5">
							<div className="w-1/2 sm:w-1/3">Price</div>
							<div className="w-1/2 sm:w-1/3">Qty</div>
							<div className="hidden sm:block w-1/3">Sub Total</div>
						</div>
					</div>
					{orderLines &&
						orderLines.map((line, index) => (
							<OrderProductRow
								key={index}
								title={line.title ? line.title : "Error loading product title"}
								price={line.price_formatted}
								qty={line.qty}
								subtotal={line.subtotal_formatted}
							/>
						))}

					<OrderProductBottom
						total={order?.total_formatted}
						shipping={order?.shipping_cost}
						vat_receipt={order?.invoices}
					/>
					{order?.dispatches &&
						order?.dispatches.map((dispatch, index) => (
							<OrderProductConsignments index={index} data={dispatch} />
						))}
				</div>
			)}
		</div>
	);
}

export default OrderProductTable;
