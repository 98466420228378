import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

interface Props {
	text?: string;
}

const GoBack = ({ text }: Props) => {
	let navigate = useNavigate();
	const onClick = () => {
		navigate(-1); //TODO: Update to work with state actually returning
	};

	return (
		<div className="cursor-pointer flex items-center" onClick={onClick}>
			<BsArrowLeft fill="#1D2548" size={30} />
			{text ? <div className="ml-2">{text}</div> : <div></div>}
		</div>
	);
};

export default GoBack;
