import dayjs from "dayjs";
import { useContext } from "react";
import { InvoiceMultiActionerActionEnum, InvoiceMultiActionerCtx } from "../../contexts/InvoiceMultiActioner";
import { API_BASE_URL } from "../../services/api/api";
import { Invoice } from "../../types/Invoice";
import Button from "../Common/Button";
import Checkbox from "../Common/Checkbox";

interface Props {
	invoice: Invoice;
}

function AccountInvoiceActionable({ invoice }: Props) {
	const { state, dispatch } = useContext(InvoiceMultiActionerCtx);
	const isSelected = state.selectedIds.some((item: string) => item === invoice.id);

	const toggle = (id: string) => {
		dispatch!({
			type: InvoiceMultiActionerActionEnum.TOGGLE_SELECTED_ID,
			payload: id,
		});
	};

	const handlePdf = () => {
		window.open(API_BASE_URL + `/invoices/${invoice.id}/download`, "_blank");
	};
	return (
		<div>
			<div className="flex flex-wrap justify-between items-center py-3 md:py-5 border-b border-dashed font-semibold text-primary text-xs md:text-sm">
				<div className="w-1/3 sm:w-1/6 uppercase pr-1">
					{/* {invoice.has_outstanding ? (
						<Checkbox checked={isSelected} onChange={() => toggle(invoice.id)} label={invoice.id} />
					) : (
						<div>{invoice.id}</div>
					)} */}
					<div>{invoice.id}</div>
				</div>
				<div className="w-1/6 uppercase pr-1 hidden sm:block">
					{dayjs.unix(invoice.created).format("DD/MM/YYYY")}
				</div>
				<div className="w-1/3 sm:w-1/6 uppercase pr-1">&pound;{invoice.total_formatted}</div>
				{invoice.has_outstanding && (
					<div className="w-1/3 sm:w-1/6 uppercase pr-1">&pound;{invoice.payments_formatted}</div>
				)}
				<div className="w-full mt-3 sm:mt-0 sm:w-auto sm:flex-1 flex sm:justify-end">
					<Button onClick={handlePdf} text="DOWNLOAD PDF" />
				</div>
			</div>
		</div>
	);
}

export default AccountInvoiceActionable;
