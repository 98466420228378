import StandardLayout from "../components/Layout/StandardLayout";

function NotFoundPage() {
	return (
		<StandardLayout>
			<div className="flex flex-col items-center">Sorry, that page could not be found</div>
		</StandardLayout>
	);
}

export default NotFoundPage;
