import AnimateHeight from "react-animate-height";
import { useNavigate } from "react-router-dom";
import { Appointments } from "../../types/Appointment";
import Button from "../Common/Button";

interface Props {
	height?: any;
	data: Appointments;
}

function AppointmentReveal({ height, data }: Props) {
	let navigate = useNavigate();
	const handleVisit = () => {
		let path = "/visit/" + data.id;
		navigate(path, { state: [data] });
	};

	return (
		<AnimateHeight duration={500} height={height}>
			<div className="py-3">
				<div className="flex">
					<div className="justify-between md:justify-start flex-1 flex flex-wrap text-sm">
						<div className="w-1/2 md:w-[180px] xl:w-[220px] pr-1">
							<div className="uppercase text-black mb-2">Full Address</div>
							<div className="text-secondary leading-5">
								<div>{data.address}</div>
								{data.address_notes ? <div className="text-xs">{data.address_notes}</div> : ""}
							</div>
						</div>
						<div className="w-1/2 md:w-[180px] xl:w-[220px] pr-1">
							<div className="pb-6">
								<div className="tracking-wider uppercase text-black mb-2">Contact Name</div>
								<div className="text-secondary whitespace-pre-wrap">{data.name}</div>
							</div>

							<div className="pb-6">
								<div className="tracking-wider uppercase text-black mb-2">Contact Number</div>
								<div className="text-secondary whitespace-pre-wrap">{data.phone}</div>
							</div>
						</div>

						<div className="flex-1">
							<div className="pb-6">
								<div className="tracking-wider uppercase text-black mb-2">Description</div>
								<div className="text-secondary text-sm">{data.description}</div>
							</div>
							<div className="pb-6">
								<div className="tracking-wider uppercase text-black mb-2">Notes</div>
								{data.notes && (
									<div className="text-sm text-primaryDarker">
										{data.notes.map((note: any, index: number) => (
											<div key={index}>
												{note.label ? <div className="font-semibold">{note.label}</div> : ""}
												<div>{note.note}</div>
											</div>
										))}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="hidden md:block shrink-0 w-[50px] h-[10px]"></div>
				</div>
				<Button text="VIEW VISIT" onClick={handleVisit} />
			</div>
		</AnimateHeight>
	);
}

export default AppointmentReveal;
