import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { clearContactState } from "../../redux/reducers/userSlice";
import { store } from "../../redux/store";
import { logout } from "../../services/api/auth";
import Button from "../Common/Button";

interface Props {
	title?: string;
}

function Header({ title }: Props) {
	const [toggleModal, setToggleModal] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const navigate = useNavigate();

	const handleLogout = () => {
		setIsProcessing(true);
		logout()
			.then(() => {
				store.dispatch(clearContactState());
				navigate("/login");
			})
			.finally(() => setIsProcessing(false));
	};

	return (
		<>
			{/*TODO: Move Modal to custom component*/}
			<Transition.Root show={toggleModal} as={Fragment}>
				<Dialog as="div" className="fixed z-10 inset-0" onClose={() => setToggleModal(false)}>
					<div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						<span className="inline-block align-middle h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-0 scale-95"
							enterTo="opacity-100 translate-y-0 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 scale-100"
							leaveTo="opacity-0 translate-y-0 scale-95"
						>
							<div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full p-6">
								<div className="flex items-start">
									<div className="mt-0 text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											Are you sure you want to log out?
										</Dialog.Title>
									</div>
								</div>
								<div className="mt-4 flex justify-between">
									<Button text="Cancel" onClick={() => setToggleModal(false)} theme="red" />
									<Button text="Log out" isProcessing={isProcessing} onClick={handleLogout} />
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>

			<header className="flex justify-between items-center p-[27px] bg-white z-10">
				<Link to="/">
					<div className="w-[162px] sm:w-[202px]">
						<img
							className="w-full block"
							src={process.env.PUBLIC_URL + "/svgs/Logo.svg"}
							alt="Process Instruments"
						/>
					</div>
				</Link>

				{
					//Was going to make this a component but only time title styled like this
					title && (
						<div className="font-body text-primary hidden md:block text-[36px] lg:text-[42px]">{title}</div>
					)
				}

				<div className="w-[202px] flex justify-end">
					<Button text="Log Out" largeStyling theme="red" onClick={() => setToggleModal(true)} />
				</div>
			</header>
		</>
	);
}

export default Header;
