import { useState } from "react";
import PageTitle from "../components/Common/PageTitle";
import StandardLayout from "../components/Layout/StandardLayout";
import ScheduleList from "../components/Schedule/ScheduleList";
import SearchBar from "../components/Search/SearchBar";

const SchedulePage = () => {
	const [searchTerm, setSearchTerm] = useState<undefined | string>(undefined);
	return (
		<StandardLayout>
			<div className="flex items-center justify-between">
				<PageTitle title="Instrument Schedule" />
				<SearchBar placeholder="Search Schedule" value={searchTerm} onChange={setSearchTerm} />
			</div>
			<ScheduleList searchTerm={searchTerm} />
		</StandardLayout>
	);
};

export default SchedulePage;
