import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectIsEngineer } from "../../redux/reducers/userSlice";
import Watermark from "../Misc/Watermark";
import Header from "./Header";
import Sidebar from "./Sidebar";

interface Props {
	children: ReactNode;
}

export default function StandardLayout({ children }: Props) {
	const isEngineer = useSelector(selectIsEngineer);

	return (
		<div className="min-h-screen flex flex-col bg-body">
			<Header title={`${isEngineer ? "Engineer" : "Customer"} Portal`} />
			<div className="flex flex-1 z-10 flex-col main:flex-row">
				<Sidebar />
				<div className="flex-1 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">{children}</div>
			</div>
			<Watermark />
		</div>
	);
}
