import { AxiosError, AxiosResponse } from "axios";
import { Schedule } from "../../types/Schedule";
import { api } from "./api";

export interface FetchPaginatedScheduleResponse {
	results: Schedule[];
	sort_method: null;
	total_results: number;
	next_page: number;
}

export const fetchPaginatedSchedule = async (queryKey: string | string[], pageParam: number) => {
	try {
		const { data } = await api.get<never, AxiosResponse<FetchPaginatedScheduleResponse>>(
			`/schedule?page=${pageParam}`
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const searchSchedule = async (queryKey: string[], pageParam: number) => {
	const searchTerm = queryKey.pop();
	try {
		const { data } = await api.post<{ query: string }, AxiosResponse<FetchPaginatedScheduleResponse>>(
			`/schedule/search?page=${pageParam}`,
			{ query: searchTerm }
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};
