import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Common/Button";
import NoDataReturned from "../components/Common/NoDataReturned";
import SkeletonList from "../components/Common/SkeletonList";
import TicketsLayout from "../components/Layout/TicketsLayout";
import { useInfiniteQuery } from "../hooks/useInfiniteQuery";
import {
	fetchAllClosedTickets,
	fetchAllOpenTickets,
	searchTicketsOpen,
	searchTicketsClosed,
} from "../services/api/tickets";
import { Ticket } from "../types/Ticket";

interface Props {
	mode?: "open" | "closed";
}

const TicketsPage = ({ mode = "open" }: Props) => {
	const observerElem = useRef(null);
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState<undefined | string>(undefined);

	const isSearching = searchTerm !== undefined && searchTerm.trim() !== "";

	const openTicketsQuery = useInfiniteQuery({
		queryKey: ["Tickets", mode],
		queryFn: fetchAllOpenTickets,
		loadMoreRef: observerElem,
		enabled: mode === "open" && !isSearching,
	});

	const closedTicketsQuery = useInfiniteQuery({
		queryKey: ["Tickets", mode],
		queryFn: fetchAllClosedTickets,
		loadMoreRef: observerElem,
		enabled: mode === "closed" && !isSearching,
	});

	const searchOpenQuery = useInfiniteQuery({
		queryKey: ["Tickets", "Search", searchTerm ?? ""],
		queryFn: searchTicketsOpen,
		loadMoreRef: observerElem,
		enabled: mode === "open" && isSearching === true,
	});

	const searchClosedQuery = useInfiniteQuery({
		queryKey: ["Tickets", "Search", searchTerm ?? ""],
		queryFn: searchTicketsClosed,
		loadMoreRef: observerElem,
		enabled: mode === "closed" && isSearching === true,
	});

	let query = closedTicketsQuery;
	let NoTicketsText = "You have no closed tickets available";
	if (mode === "open" && !isSearching) {
		query = openTicketsQuery;
		NoTicketsText = "You have no open tickets available";
	} else if (mode === "open" && isSearching) {
		query = searchOpenQuery;
		NoTicketsText = "You have no open tickets matching ’" + searchTerm + "’ available";
	} else if (mode === "closed" && isSearching) {
		query = searchClosedQuery;
		NoTicketsText = "You have no closed tickets matching ’" + searchTerm + "’ available";
	}

	return (
		<TicketsLayout mode={mode} search={true} searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
			<div className="bg-white px-3 pb-5 md:px-5">
				{query.status === "loading" ? (
					<div className="pt-3">
						<SkeletonList rows={10} />
					</div>
				) : query.status === "error" ? (
					<p>Error: {query.error.message}</p>
				) : (
					<>
						{query.data?.pages[0].results.length ? (
							<>
								{query.data?.pages.map((page, i) =>
									page.results?.map((ticket: Ticket, index: number) => (
										<div
											key={index}
											className="flex justify-between items-center py-3 border-dashed border-b border-offwhite text-primary"
										>
											<div className="w-[200px] xl:w-[240px]">
												<div className="font-semibold">{ticket.label}</div>
												<div>{ticket.status.label}</div>
											</div>
											<div className="hidden xl:block flex-1 text-sm">
												<p>
													{ticket.description.slice(0, 200) +
														(ticket.description.length > 200 ? "..." : "")}
												</p>
											</div>
											<div className="hidden lg:flex w-[180px] xl:w-[220px] ml-40 justify-between text-sm">
												<div>{dayjs.unix(ticket.created).format("DD/MM/YYYY - HH:mm")}</div>
												<div>{ticket.priority.label}</div>
											</div>
											<div className="pl-4">
												<Button
													text="View Ticket"
													onClick={() => navigate(`/tickets/${ticket.id}`)}
												/>
											</div>
										</div>
									))
								)}
								<div ref={observerElem}>
									{query.isFetchingNextPage ? (
										<SkeletonList rows={3} />
									) : query.hasNextPage ? (
										<SkeletonList rows={3} />
									) : (
										""
									)}
								</div>
							</>
						) : (
							<div className="pt-5">
								<NoDataReturned text={NoTicketsText} />
							</div>
						)}
					</>
				)}
			</div>
		</TicketsLayout>
	);
};

export default TicketsPage;
