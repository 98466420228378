import classNames from "classnames";

interface Props {
	title: string | undefined;
	price: string;
	qty: string;
	subtotal: string;
	border?: boolean;
}

function OrderProductRow({ border = false, title, price, qty, subtotal }: Props) {
	return (
		<div
			className={classNames(
				"flex uppercase font-medium text-display text-secondary text-sm mb-3 pt-1 pb-3 border-b-2",
				border ? "border-gray" : "border-body"
			)}
		>
			<div className="flex-1">{title}</div>
			<div className="w-[200px] sm:w-[270px] text-right sm:text-left flex leading-3 justify-between">
				<div className="w-1/2 sm:w-1/3">&pound;{price}</div>
				<div className="w-1/2 sm:w-1/3">{qty}</div>
				<div className="hidden sm:block w-1/3">&pound;{subtotal}</div>
			</div>
		</div>
	);
}

export default OrderProductRow;
