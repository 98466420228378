import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { setUser } from "../../redux/reducers/userSlice";
import { store } from "../../redux/store";
import { login } from "../../services/api/auth";
import Button from "../Common/Button";
import { useNavigate } from "react-router-dom";

export const LoginForm = ({ mode = "customer" }: { mode: "engineer" | "customer" }) => {
	const { register, handleSubmit } = useForm();
	const [toggleModal, setToggleModal] = useState(false);
	const [error, setError] = useState<null | string>(null);
	const [isProcessing, setIsProcessing] = useState(false);
	const navigate = useNavigate();

	const onSubmit = async (data: any) => {
		setError(null);
		setIsProcessing(true);
		try {
			const res = await login(data.email, data.password, mode);
			const userData = res.data.user ?? res.data.contact;

			if (userData) {
				store.dispatch(setUser(userData));
			}
		} catch (err: any) {
			setError(err.response.data.message);
		}

		setIsProcessing(false);
	};

	return (
		<>
			<div className="flex flex-col justify-center flex-1">
				<div className="w-[400px] bg-primary p-5 rounded">
					<div className="font-body text-white text-center text-2xl tracking-wide pb-3">
						{mode === "engineer" ? "Engineer" : ""} Login
					</div>

					<div className="mt-1 w-full">
						{error && (
							<div className="bg-red py-2 px-4 text-center text-white mb-4 rounded text-sm">{error}</div>
						)}
						<form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
							<input
								className="appearance-none block w-full rounded-md text-sm p-2"
								type="email"
								{...register("email")}
							/>
							<input
								className="appearance-none block w-full rounded-md text-sm p-2"
								type="password"
								{...register("password")}
							/>

							{/* <Input
								type="email"
								autoComplete="email"
								placeholder="Email Address"
								{...register("email")}
							/>
							<Input
								{...register("password")}
								type="password"
								autoComplete="current-password"
								placeholder="Password"
							/> */}

							<div className="flex items-center justify-between">
								<div className="mr-3">
									<Button isProcessing={isProcessing} text="Login" theme="red" type="submit" />
								</div>
								{mode !== "engineer" && (
									<div className="text-xs">
										<button
											onClick={() => navigate("/forgot-password")}
											type="button"
											className="font-medium text-white transition-opacity hover:opacity-70"
										>
											Forgot your password?
										</button>
									</div>
								)}
							</div>
						</form>
					</div>
				</div>

				{mode !== "engineer" && (
					<div className="w-[400px] bg-primary mt-4 p-5 rounded">
						<div className="font-body text-white text-center text-2xl tracking-wide pb-3">Register</div>
						<div>
							<Button
								className="w-full"
								text="Create an account"
								theme="red"
								onClick={() => navigate("/register")}
							/>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
