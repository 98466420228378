import { useState } from "react";
import PageTitle from "../components/Common/PageTitle";
import StandardLayout from "../components/Layout/StandardLayout";
import OrderTable from "../components/OrderTable/OrderTable";
import SearchBar from "../components/Search/SearchBar";

//Shows all orders with pagination
function OrdersPage() {
	const [searchTerm, setSearchTerm] = useState<undefined | string>(undefined);
	return (
		<StandardLayout>
			<div className="flex items-center justify-between">
				<PageTitle title="Orders" />
				<SearchBar placeholder="Search Orders" value={searchTerm} onChange={setSearchTerm} />
			</div>
			<OrderTable searchTerm={searchTerm} />
		</StandardLayout>
	);
}

export default OrdersPage;
