import { AxiosError } from "axios";
import { useCallback, useEffect } from "react";
import { useInfiniteQuery as useReactQueryInfiniteQuery } from "react-query";

type Props = {
	queryKey: string | string[];
	queryFn: (queryKey: any, pageParam: number) => any;
	loadMoreRef?: any;
	enabled?: boolean;
};

export const useInfiniteQuery = ({ queryKey, queryFn, loadMoreRef, enabled = true }: Props) => {
	const query = useReactQueryInfiniteQuery(queryKey, ({ pageParam = 1 }) => queryFn(queryKey, pageParam), {
		getNextPageParam: (page: any) => page?.next_page,
		getPreviousPageParam: (page: any) => page?.next_page,
		onError: (err: AxiosError) => err,
		enabled,
	});
	const { fetchNextPage, hasNextPage } = query;

	const handleObserver = useCallback(
		(entries: any) => {
			const [target] = entries;
			if (target.isIntersecting && hasNextPage) {
				fetchNextPage();
			}
		},
		[fetchNextPage, hasNextPage]
	);

	useEffect(() => {
		const element = loadMoreRef.current;
		if (!element) {
			return;
		}
		const option = { threshold: 0 };

		const observer = new IntersectionObserver(handleObserver, option);
		observer.observe(element);
		return () => observer.unobserve(element);
	}, [fetchNextPage, hasNextPage, handleObserver, loadMoreRef]);

	return query;
};
