import AccountDetailsItem from "./AccountDetailsItem";

interface Props {
	data?: any;
}

function AccountDetailsAddresses({ data }: Props) {
	return (
		<div className="flex flex-wrap">
			{data?.delivery_address && <AccountDetailsItem label="Delivery Address" value={data?.delivery_address} />}
			{data?.delivery_address && <AccountDetailsItem label="Billing Address" value={data?.billing_address} />}
		</div>
	);
}

export default AccountDetailsAddresses;
