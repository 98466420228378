import "@fontsource/bebas-neue";
import "@fontsource/inter/variable-full.css";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { selectIsEngineer, selectUser } from "./redux/reducers/userSlice";
import "react-toastify/dist/ReactToastify.css";
import LoadingFullscreen from "./components/Common/LoadingFullscreen";
import { useAuth } from "./hooks/useAuth";
import AccountDetailsPage from "./pages/AccountDetailsPage";
import AccountInvoicePage from "./pages/AccountInvoicePage";
import DocumentsPage from "./pages/DocumentsPage";
import HomeCustomerPage from "./pages/HomeCustomerPage";
import HomeEngineerPage from "./pages/HomeEngineerPage";
import LoginPage from "./pages/LoginPage";
import NewTicketPage from "./pages/NewTicketPage";
import NotFoundPage from "./pages/NotFoundPage";
import OrdersPage from "./pages/OrdersPage";
import SchedulePage from "./pages/SchedulePage";
import TicketPage from "./pages/TicketPage";
import VisitPage from "./pages/VisitPage";
import AppointmentPage from "./pages/AppointmentsPage";
import TicketsPage from "./pages/TicketsPage";
import AppointmentsPage from "./pages/AppointmentsPage";
import RegisterPage from "./pages/RegisterPage";
import ForgottenPasswordPage from "./pages/ForgottenPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import WelcomePage from "./pages/WelcomePage";

export default function App() {
	useAuth();
	const user = useSelector(selectUser);
	const isEngineer = useSelector(selectIsEngineer);

	if (user === undefined) {
		return <LoadingFullscreen />;
	}

	return (
		<>
			<ToastContainer />
			<Routes>
				{user ? (
					isEngineer ? (
						<>
							{/* <Route path="/" element={<HomeEngineerPage />} /> */}
							<Route path="/" element={<AppointmentsPage mode="upcoming" />} />
							<Route path="*" element={<NotFoundPage />} />
							<Route path="/schedule" element={<AppointmentsPage mode="upcoming" />} />
							<Route path="/schedule/past" element={<AppointmentsPage mode="past" />} />
							<Route path="/login/engineer" element={<Navigate to="/" replace={true} />} />
							<Route path="/visit/active" element={<VisitPage active={true} />} />
							<Route path="/visit/:id" element={<VisitPage />} />
						</>
					) : (
						<>
							<Route path="/" element={<HomeCustomerPage />} />
							<Route path="/orders" element={<OrdersPage />} />
							{/* <Route path="/account" element={<AccountPage />} /> */}
							<Route path="/account" element={<AccountInvoicePage />} />
							<Route path="/account/invoices" element={<AccountInvoicePage />} />
							<Route path="/account/details" element={<AccountDetailsPage />} />
							<Route path="/schedule" element={<SchedulePage />} />
							<Route path="/documents" element={<DocumentsPage />} />
							<Route path="/tickets" element={<TicketsPage mode="open" />} />
							<Route path="/tickets/open" element={<TicketsPage mode="open" />} />
							<Route path="/tickets/closed" element={<TicketsPage mode="closed" />} />
							<Route path="/tickets/new" element={<NewTicketPage />} />
							<Route path="/tickets/:id" element={<TicketPage />} />
							<Route path="/login" element={<Navigate to="/" replace={true} />} />
							<Route path="*" element={<NotFoundPage />} />
						</>
					)
				) : (
					<>
						<Route path="/register" element={<RegisterPage />} />
						<Route path="/forgot-password" element={<ForgottenPasswordPage />} />
						<Route path="/reset-password/:token" element={<ResetPasswordPage />} />
						<Route path="/welcome/:token" element={<WelcomePage />} />
						<Route path="/login" element={<LoginPage />} />
						<Route path="/login/engineer" element={<LoginPage mode="engineer" />} />
						<Route path="*" element={<LoginPage />} />
					</>
				)}
			</Routes>
		</>
	);
}
