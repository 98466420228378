import * as React from "react";
import { Dispatch, useReducer } from "react";
import { Invoice } from "../types/Invoice";

export enum InvoiceMultiActionerActionEnum {
	SET_INVOICES = "SET_INVOICES",
	TOGGLE_SELECT_ALL = "TOGGLE_SELECT_ALL",
	TOGGLE_SELECTED_ID = "TOGGLE_SELECTED_ID",
}

export interface InvoiceMultiActionerAction {
	type: InvoiceMultiActionerActionEnum;
	payload: any;
}

interface TableContextProps {
	state?: any;
	dispatch?: Dispatch<InvoiceMultiActionerAction> | null;
}

interface ReducerState {
	selectedIds: string[];
	selectAll: boolean;
	invoices: Invoice[];
}

export const InvoiceMultiActionerReducer = (state: ReducerState, action: InvoiceMultiActionerAction) => {
	const { type } = action;
	const payload = action.payload;

	switch (type) {
		case InvoiceMultiActionerActionEnum.SET_INVOICES: {
			return {
				...state,
				invoices: payload,
			};
		}
		case InvoiceMultiActionerActionEnum.TOGGLE_SELECT_ALL: {
			const newState = { ...state };

			if (newState.selectAll) {
				newState.selectedIds = [];
				newState.selectAll = false;
			} else {
				newState.selectedIds = newState.invoices.map((invoice) => invoice.id);
				newState.selectAll = true;
			}

			return newState;
		}

		case InvoiceMultiActionerActionEnum.TOGGLE_SELECTED_ID: {
			const index = state.selectedIds.indexOf(payload);
			let newSelectedIds = [...state.selectedIds];

			if (index !== -1) {
				newSelectedIds = state.selectedIds.filter((item) => item !== payload);
			} else {
				newSelectedIds.push(payload);
			}

			return {
				...state,
				...{ selectedIds: newSelectedIds, selectAll: false },
			};
		}

		default: {
			return state;
		}
	}
};

const InvoiceMultiActionerCtx = React.createContext<TableContextProps>({});

const InvoiceMultiActionerProvider = ({ children }: { children: any }) => {
	const initialState: ReducerState = {
		selectedIds: [],
		invoices: [],
		selectAll: false,
	};
	const [state, dispatch] = useReducer(InvoiceMultiActionerReducer, initialState);

	return (
		<InvoiceMultiActionerCtx.Provider
			value={{
				state,
				dispatch,
			}}
		>
			{children}
		</InvoiceMultiActionerCtx.Provider>
	);
};

export { InvoiceMultiActionerProvider, InvoiceMultiActionerCtx };
