import { api } from "./api";

export interface AccountDetailsProps {
	first_name: string;
	last_name: string;
	phone_number: string;
	mobile_number: string;
}

export interface AccountPasswordProps {
	current_password: string;
	new_password: string;
}

export const updateAccount = async (
	first_name?: string,
	last_name?: string,
	phone_number?: string,
	mobile_number?: string
) => {
	return api.post<AccountDetailsProps>("/contact", {
		first_name,
		last_name,
		phone_number,
		mobile_number,
	});
};

export const updateAccountPassword = async (current_password: string, new_password: string) => {
	return api.post<AccountPasswordProps>("/contact/password", {
		current_password,
		new_password,
	});
};

//WIP
export const updateAccountAddress = async (current_password?: string, new_password?: string) => {
	return api.post<AccountPasswordProps>("/user/password", {
		current_password,
		new_password,
	});
};
