import AccountDetailsTable from "../components/Account/AccountDetailsTable";
import AccountLayout from "../components/Layout/AccountLayout";

function AccountDetailsPage() {
	return (
		<AccountLayout title="Account Details">
			<AccountDetailsTable />
		</AccountLayout>
	);
}

export default AccountDetailsPage;
