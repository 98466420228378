import AccountDetailsItem from "./AccountDetailsItem";

interface Props {
	data?: any;
}
function AccountDetails({ data }: Props) {
	return (
		<div className="flex flex-wrap">
			{data?.first_name && <AccountDetailsItem label="First Name" value={data?.first_name} />}
			{data?.last_name && <AccountDetailsItem label="Last Name" value={data?.last_name} />}
			<AccountDetailsItem label="Phone Number" value={data?.phone_number ? data?.phone_number : "N/A"} />
			<AccountDetailsItem label="Mobile Number" value={data?.mobile_number ? data?.mobile_number : "N/A"} />
		</div>
	);
}

export default AccountDetails;
