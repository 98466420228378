import dayjs from "dayjs";
import { useState } from "react";
import { Order } from "../../types/Order";
import OrderTableReveal from "./OrderTableReveal";

interface Props {
	order: Order;
}

//Hardcoded for now
function OrderTableRow({ order }: Props) {
	const [height, setHeight] = useState<number | string>(0);

	//Used for turning the plus into a minus
	let plusStyle = {
		transform: height === 0 ? "rotate(0deg)" : "rotate(90deg)",
		transition: "transform 150ms ease",
	};

	return (
		<div className="bg-white rounded-lg mb-4">
			<div className="flex items-center cursor-pointer" onClick={() => setHeight(height === 0 ? "auto" : 0)}>
				<div className="flex-1 grid grid-cols-4 sm:grid-cols-5 text-sm font-display text-secondary uppercase">
					<div className="self-center p-5">{order?.id}</div>
					<div className="self-center p-5">{dayjs.unix(order?.timestamp!).format("DD/MM/YYYY")}</div>
					<div className="hidden sm:block self-center p-5">{order?.count_items}</div>
					<div className="hidden sm:block self-center p-5">
						{order?.total_formatted ? "£" + order?.total_formatted : ""}
					</div>
					<div className="self-center p-5">{order?.status === null ? "N/A" : order?.status.label}</div>
				</div>
				<div className="p-5">
					<div className="w-[10px] h-[10px] relative">
						<div className="w-[10px] h-0.5 bg-primary absolute top-2/4 left-2/4 ml-[-5px] -mt-px"></div>
						<div
							className="w-0.5 h-[10px] bg-primary absolute top-2/4 left-2/4 mt-[-5px] -ml-px"
							style={plusStyle}
						></div>
					</div>
				</div>
			</div>
			<OrderTableReveal height={height} order={order} />
		</div>
	);
}

export default OrderTableRow;
