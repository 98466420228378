import { useMutation } from "react-query";
import { createTicket, CreateTicketProps } from "../services/api/tickets";

export default function useCreateTicket() {
	return useMutation(({ label, description, crm_ticket_priority_id, crm_ticket_type_id }: CreateTicketProps) => {
		return createTicket({
			label,
			description,
			crm_ticket_priority_id,
			crm_ticket_type_id,
		});
	});
}
