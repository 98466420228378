import { AxiosError, AxiosResponse } from "axios";
import { Appointments, Notes } from "../../types/Appointment";
import { Task } from "../../types/Task";
import { api } from "./api";

export interface FetchAllAppointmentsResponse {
	status: string;
	message: string;
	data: {
		next_page: string | null;
		results: Appointments[];
	};
}

export const fetchAllAppointments = async (queryKey: string | string[], pageParam: number) => {
	try {
		const { data } = await api.get<never, AxiosResponse<FetchAllAppointmentsResponse>, AxiosError<any>>(
			`/appointments?page=${pageParam}`
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const fetchAllUpcomingAppointments = async (queryKey: string | string[], pageParam: number) => {
	try {
		const { data } = await api.get<never, AxiosResponse<FetchAllAppointmentsResponse>, AxiosError<any>>(
			`/appointments/future?page=${pageParam}`
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const fetchAllPreviousAppointments = async (queryKey: string | string[], pageParam: number) => {
	try {
		const { data } = await api.get<never, AxiosResponse<FetchAllAppointmentsResponse>, AxiosError<any>>(
			`/appointments/previous?page=${pageParam}`
		);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const fetchAppointment = async (id: string) => {
	try {
		const { data } = await api.get<never, AxiosResponse<Appointments>, AxiosError<any>>(`/appointments/${id}`);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const fetchAppointmentTasks = async (id: string) => {
	try {
		const { data } = await api.get<never, AxiosResponse<Task[]>, AxiosError<any>>(`/appointments/${id}/tasks`);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const setAppointmentTimeLog = async ({ id, hours, minutes }: { id: string; hours: string; minutes: string }) => {
	try {
		const { data } = await api.post<{ hours_actual: { hours: string; minutes: string } }>(`/appointments/${id}`, {
			hours_actual: { hours, minutes },
		});

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const fetchNextAppointment = async () => {
	try {
		const { data } = await api.get<never, AxiosResponse<Appointments>, AxiosError<any>>(`/appointments/next`);

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};

export const setUpdateAppointment = async ({
	id,
	status_id,
	notes = [],
	tasks = [],
}: {
	id: string;
	status_id: string;
	notes: { id: string; notes: string }[];
	tasks: { id: string; notes: string }[];
}) => {
	try {
		const { data } = await api.post(`/appointments/${id}/update`, {
			status_id: status_id,
			tasks,
			notes,
		});

		return data;
	} catch (err) {
		const error = err as AxiosError;
		throw new Error(error.message);
	}
};
