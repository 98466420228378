import { useRef } from "react";
import { useInfiniteQuery } from "../../hooks/useInfiniteQuery";
import { fetchAllOrders, searchOrders } from "../../services/api/orders";
import EndOfPages from "../Common/EndOfPages";
import NoDataReturned from "../Common/NoDataReturned";
import SkeletonList from "../Common/SkeletonList";
import OrderTableHeader from "./OrderTableHeader";
import OrderTableRow from "./OrderTableRow";

function OrderTable({ searchTerm }: { searchTerm?: undefined | string }) {
	const observerElem = useRef(null);
	const isSearching = searchTerm !== undefined && searchTerm.trim() !== "";

	const allOrdersQuery = useInfiniteQuery({
		queryKey: "fetchAllOrders",
		queryFn: fetchAllOrders,
		loadMoreRef: observerElem,
		enabled: isSearching === false,
	});

	const searchQuery = useInfiniteQuery({
		queryKey: ["Orders", "Search", searchTerm ?? ""],
		queryFn: searchOrders,
		loadMoreRef: observerElem,
		enabled: isSearching === true,
	});

	const query = isSearching ? searchQuery : allOrdersQuery;

	return query.status === "loading" ? (
		<SkeletonList rows={10} />
	) : query.status === "error" ? (
		<p>Error: {query.error.message}</p>
	) : (
		<>
			{query.data?.pages[0].results.length ? (
				<>
					{isSearching ? <div className="mb-4 text-sm">Showing results for: {searchTerm}</div> : null}
					<OrderTableHeader />
					{query.data?.pages.map((page, i) =>
						page.results?.map((order: any, index: number) => <OrderTableRow key={index} order={order} />)
					)}
					<div ref={observerElem}>
						{query.isFetchingNextPage ? (
							<SkeletonList rows={3} />
						) : query.hasNextPage ? (
							<SkeletonList rows={3} />
						) : (
							<EndOfPages text="You've reached the end of your orders" />
						)}
					</div>
				</>
			) : (
				<NoDataReturned text="You have no orders on your account" />
			)}
		</>
	);
}

export default OrderTable;
