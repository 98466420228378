import AnimateHeight from "react-animate-height";
import { useQuery } from "react-query";
import { fetchOrderLines } from "../../services/api/orders";
import { Order } from "../../types/Order";
import OrderProductTable from "./OrderProductTable";
import OrderTableDetails from "./OrderTableDetails";

interface Props {
	height?: any;
	order: Order;
}

function OrderTableReveal({ height, order }: Props) {
	const { data: orderLines } = useQuery({
		queryFn: () => fetchOrderLines(order.id),
		queryKey: `Order.${order.id}.Lines`,
		enabled: height !== 0,
	});

	return (
		<AnimateHeight duration={500} height={height}>
			<div className="p-5 pt-0">
				<div className="border-t-2 border-body py-4 flex">
					<div className="max-w-full sm:max-w-[600px] w-full">
						<OrderTableDetails order={order} />
					</div>
				</div>
				<OrderProductTable orderLines={orderLines} order={order} />
			</div>
		</AnimateHeight>
	);
}

export default OrderTableReveal;
