import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { setAppointmentTimeLog } from "../../services/api/appointments";
import Button from "../Common/Button";

interface InitialValues {
	time: string;
}

export default function AppointmentTimeLog({
	id,
	value,
}: {
	id: string;
	value: {
		hours: number;
		minutes: number;
	};
}) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<InitialValues>();
	const { mutate, isLoading } = useMutation(
		({ id, hours, minutes }: { id: string; hours: string; minutes: string }) =>
			setAppointmentTimeLog({ id, hours, minutes }),
		{
			onSuccess: () => {
				reset();
				toast.success("Time log saved", { theme: "colored", position: "top-center" });
			},
		}
	);

	const onSubmit = (values: InitialValues) => {
		const time = values.time;
		if (time === "" || time === "00:00") {
			return;
		}
		const [hours, minutes] = values.time.split(":");
		mutate({ id, hours, minutes });
	};
	return (
		<div className="mt-4 xl:mt-0 w-full xl:w-[250px]">
			<div className="max-w-[250px] flex items-center">
				<div className="flex-1">
					<input
						className={
							"appearance-none block w-full text-sm py-[7px] p-2 border border-primaryDarker rounded-l-lg"
						}
						autoComplete={"off"}
						type="time"
						defaultValue={`${value.hours < 10 ? "0" + value.hours : value.hours}:${
							Math.round(value.minutes) < 10 ? "0" + Math.round(value.minutes) : Math.round(value.minutes)
						}`}
						{...register("time")}
						placeholder="Manual Entry (HH:MM)"
					/>
				</div>
				<Button
					className="rounded-r-lg"
					isProcessing={isLoading}
					text="Confirm"
					inline={true}
					onClick={handleSubmit(onSubmit)}
				/>
			</div>
		</div>
	);
}
