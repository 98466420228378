import { AxiosResponse } from "axios";
import { Invoice } from "../../types/Invoice";
import { api } from "./api";

export const fetchAllOutstandingInvoices = async () => {
	const { data } = await api.get<never, AxiosResponse<Invoice[]>>(`/invoices/outstanding`);

	return data;
};

export interface FetchPaginatedPreviousInvoicesResponse {
	results: Invoice[];
	sort_method: null;
	total_results: number;
	next_page: number;
}

export const fetchPaginatedPreviousInvoices = async (queryKey: string | string[], pageParam: number) => {
	const { data } = await api.get<never, AxiosResponse<FetchPaginatedPreviousInvoicesResponse>>(
		`/invoices/previous?page=${pageParam}`
	);

	return data;
};
