import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import AppointmentTimeLog from "../components/Appointment/AppointmentTimeLog";
import Button from "../components/Common/Button";
import Loading from "../components/Common/Loading";
import NoDataReturned from "../components/Common/NoDataReturned";
import PageTitle from "../components/Common/PageTitle";
import HardwareList from "../components/Hardware/HardwareList";
import StandardLayout from "../components/Layout/StandardLayout";
import useAppointment from "../hooks/useAppointment";
import useUpdateAppointment from "../hooks/useUpdateAppointment";

interface Props {
	active?: boolean;
}

const VisitPage = ({ active = false }: Props) => {
	const { id } = useParams() as { id: string };
	const query = useAppointment(id, true);
	const { mutate, isLoading } = useUpdateAppointment();

	const loading = query.isLoading;
	const appointment = query.data;
	const title = active ? "Active Visit" : "Visit";
	const methods = useForm<any>({});

	const onSubmit = (values: any) => {
		// Convert images to base64
		mutate({ id, status_id: "3", notes: values.notes, tasks: values.tasks });
	};

	return (
		<StandardLayout>
			<PageTitle title={title} />
			{loading ? (
				<Loading />
			) : appointment ? (
				<>
					<div className="flex justify-between items-center bg-primaryDarker py-2 px-4 text-white font-display rounded-t-md text-sm sm:text-base">
						<div className="flex-1 flex items-center">
							{appointment?.schedule_date ? (
								<div className="mr-3">
									{dayjs.unix(appointment.schedule_date).format("DD/MM/YYYY -  HH:mm:ss")}
								</div>
							) : (
								""
							)}
							<div className="hidden md:block mr-3">{appointment?.address}</div>
						</div>
						{/*}
				<div className="flex items-center">
					Active
					<div
						className={classNames("ml-5 h-[10px] w-[10px] rounded-full shrink-0", `${themeMap["green"]}`)}
					></div>
	</div>*/}
					</div>
					<div className="p-4 bg-white">
						<div className="flex flex-wrap">
							<div className="flex-1 flex flex-wrap text-sm pr-10">
								<div className="w-1/2 sm:w-1/3 pr-9">
									<div className="uppercase text-black mb-2">Full Address</div>
									<div className="text-secondary leading-5">
										{/* {order.addresses.delivery.map((row, index) => (
                                    <div key={index}>{row}</div>
                                ))} */}
										{appointment?.address}
									</div>
								</div>

								<div className="basis-6/12">
									<div className="pb-6">
										<div className="tracking-wider uppercase text-black mb-2">Contact Name</div>
										<div className="text-secondary whitespace-pre-wrap">{appointment?.name}</div>
									</div>

									<div className="pb-6">
										<div className="tracking-wider uppercase text-black mb-2">Contact Number</div>
										<div className="text-secondary whitespace-pre-wrap">{appointment?.phone}</div>
									</div>
								</div>
								<div className="w-full">
									<div className="uppercase text-black mb-2">Description</div>
									<div className="text-secondary text-sm leading-5">
										<div>{appointment?.description}</div>
									</div>
								</div>
							</div>
							<AppointmentTimeLog id={id} value={appointment.hours.actual} />
						</div>

						<FormProvider {...methods}>
							<form onSubmit={methods.handleSubmit(onSubmit)}>
								<div className="w-full mt-8">
									<div className="uppercase text-black mb-2">Notes</div>
									{appointment?.notes.length === 0 ? (
										<div>
											<textarea
												{...methods.register(`notes[0].notes`)}
												className="appearance-none text-sm text-primaryDarker leading-5 rounded-md border border-primaryDarker py-2 p-3 w-full min-h-[120px]"
											></textarea>
											<input {...methods.register(`notes[0].id`)} type="hidden" value={0} />
										</div>
									) : (
										appointment?.notes.map((note, index) => {
											return (
												<div key={note.id}>
													<textarea
														{...methods.register(`notes[${index}].notes`)}
														defaultValue={note.note}
														className="appearance-none text-sm text-primaryDarker leading-5 rounded-md border border-primaryDarker py-2 p-3 w-full min-h-[120px]"
													></textarea>
													<input
														{...methods.register(`notes[${index}].id`)}
														type="hidden"
														value={note.id}
													/>
												</div>
											);
										})
									)}
								</div>

								<div className="mt-5">
									<HardwareList id={id} />
									<div className="flex items-center mt-4">
										<div>
											<Button isProcessing={isLoading} text="Save Visit" type="submit" />
										</div>
									</div>
								</div>
							</form>
						</FormProvider>
					</div>
				</>
			) : (
				<NoDataReturned text="No appointment data available" />
			)}
		</StandardLayout>
	);
};

// const VisitPageOld = ({ active = false }: Props) => {
// 	const nextAppointment = useQuery({
// 		queryFn: () => fetchNextAppointment(),
// 		queryKey: `AppointmentNext`,
// 	});
// 	const { id } = useParams() as { id: string };
// 	const query = useAppointment(id, !active);

// 	const loading = active ? nextAppointment.isLoading : query.isLoading;
// 	const appointment = active ? nextAppointment.data : query.data;
// 	const title = active ? "Active Visit" : "Visit";

// 	console.log(appointment);

// 	const themeMap = {
// 		green: "bg-green",
// 		orange: "bg-orange",
// 		red: "bg-red",
// 	};

// 	const handleSave = () => {
// 		toast.success("Visit Saved - WIP Not actually saved", { theme: "colored", position: "top-center" });
// 	};

// 	return (
// 		<StandardLayout>
// 			<PageTitle title={title} />
// 			{loading ? (
// 				<Loading />
// 			) : appointment ? (
// 				<>
// 					<div className="flex justify-between items-center bg-primaryDarker py-2 px-4 text-white font-display rounded-t-md text-sm sm:text-base">
// 						<div className="flex-1 flex items-center">
// 							<div className="mr-3">
// 								{dayjs.unix(appointment.schedule_date).format("DD/MM/YYYY -  HH:mm")}
// 							</div>
// 							<div className="hidden md:block mr-3">{appointment.address}</div>
// 						</div>
// 						<div className="flex items-center">
// 							Active
// 							<div
// 								className={classNames(
// 									"ml-5 h-[10px] w-[10px] rounded-full shrink-0",
// 									`${themeMap["green"]}`
// 								)}
// 							></div>
// 						</div>
// 					</div>
// 					<div className="p-4 bg-white">
// 						<div className="flex flex-wrap">
// 							<div className="flex-1 flex flex-wrap text-sm pr-10">
// 								<div className="w-1/2 sm:w-1/3 pr-9">
// 									<div className="uppercase text-black mb-2">Full Address</div>
// 									<div className="text-secondary leading-5">
// 										{appointment.address}
// 										{appointment.address_notes ? (
// 											<div className="text-xs">{appointment.address_notes}</div>
// 										) : (
// 											""
// 										)}
// 									</div>
// 								</div>

// 								<div className="basis-6/12">
// 									<div className="pb-6">
// 										<div className="tracking-wider uppercase text-black mb-2">Contact Name</div>
// 										<div className="text-secondary whitespace-pre-wrap">{appointment?.name}</div>
// 									</div>

// 									<div className="pb-6">
// 										<div className="tracking-wider uppercase text-black mb-2">Contact Number</div>
// 										<div className="text-secondary whitespace-pre-wrap">{appointment?.phone}</div>
// 									</div>
// 								</div>
// 								<div className="w-full">
// 									<div className="uppercase text-black mb-2">Description</div>
// 									<div className="text-secondary text-sm leading-5">
// 										<div>{appointment?.description}</div>
// 									</div>
// 								</div>
// 							</div>
// 							<div className="mt-4 xl:mt-0 w-full xl:w-[250px]">
// 								<div className="max-w-[250px] flex items-center">
// 									<div className="flex-1">
// 										{/* <Input border={true} placeholder="Manual Entry (HH:MM)" /> */}
// 									</div>
// 									{/* <Button text="Confirm" inline={true} /> */}
// 								</div>
// 							</div>
// 						</div>

// 						{appointment.notes && (
// 							<div className="w-full mt-8">
// 								<div className="uppercase text-black mb-2">Notes</div>
// 								{appointment.notes.map((note) => (
// 									<div className="mb-2">
// 										<div className="text-black text-xs mb-2">{note.label}</div>
// 										<textarea className="appearance-none text-sm text-primaryDarker leading-5 rounded-md border border-primaryDarker py-2 p-3 w-full min-h-[120px]">
// 											{note.note}
// 										</textarea>
// 									</div>
// 								))}
// 							</div>
// 						)}

// 						<div className="mt-5">
// 							{/* <HardwareList /> */}
// 							<div className="mt-4">{/* <Button text="Save Visit" onClick={handleSave} /> */}</div>
// 						</div>
// 					</div>
// 				</>
// 			) : (
// 				<div>Something went wrong</div>
// 			)}
// 		</StandardLayout>
// 	);
// };

export default VisitPage;
