import { useRef } from "react";
import { useInfiniteQuery } from "../../hooks/useInfiniteQuery";
import { fetchAllPreviousAppointments, fetchAllUpcomingAppointments } from "../../services/api/appointments";
import SkeletonList from "../Common/SkeletonList";
import AppointmentRow from "./AppointmentRow";

interface Props {
	mode?: "upcoming" | "past";
}

const AppointmentList = ({ mode = "upcoming" }: Props) => {
	const observerElem = useRef(null);

	const upcomingQuery = useInfiniteQuery({
		queryKey: ["Tickets", mode],
		queryFn: fetchAllUpcomingAppointments,
		loadMoreRef: observerElem,
		enabled: mode === "upcoming",
	});

	const previousQuery = useInfiniteQuery({
		queryKey: ["Appointments", mode],
		queryFn: fetchAllPreviousAppointments,
		loadMoreRef: observerElem,
		enabled: mode === "past",
	});

	const query = mode === "upcoming" ? upcomingQuery : previousQuery;

	return query.status === "loading" ? (
		// <Loading />
		<SkeletonList rows={10} />
	) : query.status === "error" ? (
		<p>Error: {query.error.message}</p>
	) : (
		<>
			{query.data!.pages.map((page, i) =>
				page.results[0] ? (
					page.results?.map((data: any, index: number) => <AppointmentRow key={index} data={data} />)
				) : (
					<div key={i} className="pt-3">
						No Visits available
					</div>
				)
			)}
			<div ref={observerElem}>
				{query.isFetchingNextPage ? (
					<SkeletonList rows={3} />
				) : query.hasNextPage ? (
					<SkeletonList rows={3} />
				) : (
					""
				)}
			</div>
		</>
	);
};

export default AppointmentList;
