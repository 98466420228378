import Button from "../Common/Button";
import { API_BASE_URL } from "../../services/api/api";
import { Invoices } from "../../types/Order";
import dayjs from "dayjs";

interface Props {
	vat_receipt?: Invoices[];
	shipping?: string;
	total: string | null | undefined;
}

function OrderProductBottom({ vat_receipt, shipping = "", total }: Props) {
	const handlePdf = (invoiceId: string) => {
		window.open(API_BASE_URL + `/invoices/${invoiceId}/download`, "_blank");
	};

	return (
		<div className="mt-10">
			<div className="flex-wrap md:flex-nowrap flex flex-row md:flex-row-reverse uppercase text-sm mb-3">
				<div className="w-[270px] flex flex-col text-display text-black leading-5">
					{shipping && (
						<div className="flex">
							<div className="flex-1">Shipping</div>
							<div className="text-secondary font-medium">&pound;{shipping}</div>
						</div>
					)}
					<div className="flex">
						<div className="flex-1">Total</div>
						<div className="text-secondary font-medium">&pound;{total}</div>
					</div>
				</div>
				<div className="w-full md:flex-1">
					{vat_receipt &&
						vat_receipt.map((receipt) => (
							<div className="mt-2 flex items-center">
								<Button text="Print Vat Receipt" onClick={() => handlePdf(receipt.id)} />
								<div className="pl-2 text-sm">
									{" "}
									- {dayjs.unix(receipt.created).format("DD/MM/YYYY - HH:mm:ss")}
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
}

export default OrderProductBottom;
