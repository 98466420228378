import { Document } from "../../types/Document";

const DocumentRow = ({ document }: { document: Document }) => {
	return (
		<div className="bg-white rounded-md p-3 sm:px-5 text-secondary text-[10px] sm:text-sm font-medium font-display mb-2">
			<div className="flex items-center">
				<div className="w-1/5">{document.id}</div>
				<div className="flex-1">{document.file_name}</div>
				{document.resource.url && (
					<a
						href={document.resource.url}
						download
						target="_blank"
						rel="noreferrer"
						className="px-5 pt-[4px] pb-[3px] bg-primary hover:opacity-70 text-white group relative flex justify-center rounded-md text-base font-body tracking-wider focus:outline-none focus:ring-2 focus:ring-offset-2 "
					>
						Download
					</a>
				)}
			</div>
		</div>
	);
};

export default DocumentRow;
